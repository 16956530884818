<template>
  <div>
    <div v-for="(entityDetail, countent) in entityList" :key="countent">
      <vs-row>
        <div class="w-full mb-4 md:mb-6" :id="'entity-' + countent">
          <div class="flex mb-4 items-center">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 4V2C10 0.9 9.1 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V6C20 4.9 19.1 4 18 4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM17 16H10V14H12V12H10V10H12V8H10V6H17C17.55 6 18 6.45 18 7V15C18 15.55 17.55 16 17 16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z"
                fill="#BDBDBD"/>
            </svg>

            <h4 class="lg:text-xl md:text-lg text-sm mb-0 ml-5">Business entity {{countent+1}}</h4>
          </div>
          <hr class="line-hr mb-8" />
          <feather-icon icon="Trash2Icon" svgClasses="w-5 h-5" v-show="entityList.length>1" @click="deleteEntity(countent)" class="float-right" />
          <div class="onboarding-form">
            <div>
              <div class="flex items-center flex-wrap">
                <div class="input-grp">
                  <label class="vs-input--label p-0 w-full" :class="errors.has(`Entitytype${countent}`) ? 'text-danger' : ''">Entity type </label>
                  <vs-select
                    @change="onchangeEntityType(countent)"
                    :name="`Entitytype${countent}`"
                    v-validate="'required'"
                    class="w-full"
                    v-model="entityDetail.entityType">
                    <vs-select-item v-for="(listent, index) in entityTypeList" :key="index" :text="listent.name" :value="listent.value"></vs-select-item>
                  </vs-select>
                  <span v-show="errors.has(`Entitytype${countent}`)" class="text-danger text-sm">
                    {{
                      changeMessage(
                        errors.first(`Entitytype${countent}`),
                        `Entitytype${countent}`,
                        'entity type'
                      )
                    }}
                  </span>
                </div>
              </div>
              <div v-if="entityDetail.entityType=='Company'">
                <div class="flex items-center flex-wrap ">
                  <div class="input-grp mt-6 md:mt-10">
                    <label class="vs-input--label p-0 w-full" :class="errors.has(`Companyname${countent}`) ? 'text-danger' : ''">Company name</label>
                      <vs-input
                        type="text"
                        :name="`Companyname${countent}`"
                        v-validate="'required'"
                        class="w-full"
                        v-model="entityDetail.companyName"
                        @change="changeName(entityDetail)"
                        @keyup="handleChange(countent,'companyName')"/>
                      <span v-show="errors.has(`Companyname${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`Companyname${countent}`),
                          `Companyname${countent}`,
                          'company name'
                          )
                        }}
                      </span>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex items-center flex-wrap ">
                  <div class="flex items-center flex-wrap">
                    <div class="input-grp mt-6 md:mt-10" v-if="entityDetail.entityType=='Company'">
                      <label class="vs-input--label p-0 w-full" :class="errors.has(`ACN${countent}`) ? 'text-danger' : ''">ACN</label>
                      <vs-input
                        type="text"
                        :name="`ACN${countent}`"
                        v-validate="'required'"
                        class="w-full"
                        v-mask="['### ### ###']"
                        v-model="entityDetail.acn"
                        @keyup="acnValidationWithSpace(countent)"/>
                      <span v-show="errors.has(`ACN${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`ACN${countent}`),
                          `ACN${countent}`,
                          'ACN'
                          )
                        }}
                      </span>
                    </div>

                    <div class="input-grp mt-6 md:mt-10" v-if="entityDetail.entityType=='Company'">
                      <label class="vs-input--label p-0 w-full" :class="bag.has(`abn${countent}`)?'text-danger':''">
                        ABN (if registered under this {{entityDetail.entityType}})
                      </label>
                      <vs-input
                        type="text"
                        placeholder="Optional"
                        class="w-full"
                        :name="`abn${countent}`"
                        v-model="entityDetail.abn"
                        v-mask="['## ### ### ###']"
                        @keyup="abnValidationWithSpace(countent,`abn${countent}`)"/>

                      <span v-show="bag.first(`abn${countent}`)" class="text-danger text-sm">{{bag.first(`abn${countent}`)}}</span>
                    </div>
                  </div>
                  <div class="flex items-center flex-wrap">
                    <div class="input-grp mt-6 md:mt-10" v-if="entityDetail.entityType=='Individual'">
                      <label class="vs-input--label p-0 w-full" :class="errors.has(`Fullname${countent}`) ? 'text-danger' : ''">Full name</label>
                      <vs-input
                        type="text"
                        :name="`Fullname${countent}`"
                        v-validate="'required'"
                        class="w-full"
                        v-model="entityDetail.fullName"
                        @keyup="handleChange(countent,'fullName')"/>
                      <span v-show="errors.has(`Fullname${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`Fullname${countent}`),
                          `Fullname${countent}`,
                          'fullname'
                          )
                        }}
                      </span>
                    </div>
                    <div class="input-grp mt-6 md:mt-10" v-if="entityDetail.entityType =='Individual'">
                      <label class="vs-input--label p-0 w-full" :class="bag.first(`indAbn${countent}`)?'text-danger':''">
                        ABN (if registered under this {{entityDetail.entityType}})
                      </label>
                      <vs-input
                        type="text"
                        placeholder="Optional"
                        class="w-full"
                        :name='`indAbn${countent}`'
                        v-model="entityDetail.abn"
                        v-mask="['## ### ### ###']"
                        @keyup="abnValidationWithSpace(countent,`indAbn${countent}`)"/>
                      <span v-show="bag.first(`indAbn${countent}`)" class="text-danger text-sm">{{bag.first(`indAbn${countent}`)}}</span>
                    </div>

                    <div class="input-grp mt-6 md:mt-10" v-if="entityDetail.entityType=='Individual'">
                      <label class="vs-input--label p-0 w-full" :class="errors.has(`Mobilephonenumber${countent}`) ? 'text-danger' : ''">Mobile phone number</label>
                      <vs-input
                        :name="`Mobilephonenumber${countent}`"
                        v-validate="'required|phoneFormat'"
                        class="w-full"
                        v-model="entityDetail.mobileNumber"
                        v-mask="['#### ### ###']"
                        @keyup="handleChange(countent,'mobileNumber')"/>
                      <span v-show="errors.has(`Mobilephonenumber${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`Mobilephonenumber${countent}`),
                          `Mobilephonenumber${countent}`,
                          'mobile phone number'
                          )
                        }}
                      </span>
                    </div>

                    <div class="input-grp mt-6 md:mt-10" v-if="entityDetail.entityType=='Individual'">
                      <label class="vs-input--label p-0 w-full" :class="errors.has(`entityEmailAddr${countent}`) ? 'text-danger' : ''">Email address</label>
                      <vs-input
                        type="text"
                        :name="`entityEmailAddr${countent}`"
                        data-vv-as="email address"
                        v-validate="'required|email'"
                        class="w-full"
                        v-model="entityDetail.emailAddress"
                        @keyup="handleChange(countent,'emailAddress')"/>
                      <span v-show="errors.has(`entityEmailAddr${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`entityEmailAddr${countent}`),
                          `entityEmailAddr${countent}`,
                          'email address'
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
              <div v-if="entityDetail.entityType">
                  <!--  registered address  -->
                  <div class="mt-10 mb-4">
                    <div class="flex mt-6 md:mt-10 flex-wrap">
                      <div class="input-grp input-full">
                        <label class="text-base font-normal w-full">Registered Address</label>
                        <vue-google-autocomplete :ref="`registeredAddress${countent}`"
                          :id="`rA${countent}`"
                          classname="vs-input--input"
                          v-on:placechanged="getEmittedResponseRegisteredAddress"
                          placeholder="Start typing your address" :country="['au']"
                          autocomplete
                          @change="handleChange(countent,'registeredAddress')"/>
                      </div>
                    </div>

                    <div>
                        <div class="flex mt-6 md:mt-10 flex-wrap">
                          <div class="input-grp">
                            <label class="vs-input--label w-full">Apt, Suite, Unit, Building, Floor</label>
                            <vs-input v-model="entityDetail.registeredAddress.appartmentDetail" class="w-full" @keyup="handleChange(countent,'registeredAddress')"/>
                          </div>
                        </div>

                        <div class="flex mt-6 md:mt-10 flex-wrap">
                          <div class="input-grp">
                            <label class="vs-input--label w-full" :class="errors.has(`RstreetAddress${countent}`) ? 'text-danger' : ''" >Street Address </label>
                            <vs-input v-model="entityDetail.registeredAddress.streetAddress" v-validate="{required:(entityDetail.registeredAddress.autofill)?false:true}"
                              :name="`RstreetAddress${countent}`" data-vv-as="street address" class="w-full" @keyup="handleChange(countent,'registeredAddress')"
                              @keydown="clearAutoStatus(countent)" autocomplete/>

                            <span v-show="errors.has(`RstreetAddress${countent}`)" class="text-danger text-sm">
                              {{ changeMessage( errors.first(`RstreetAddress${countent}`), `RstreetAddress${countent}`, 'street address') }}
                            </span>
                          </div>

                          <div class="input-grp">
                            <label class="vs-input--label w-full" :class="errors.has(`Rsuburb${countent}`) ? 'text-danger' : ''" >Suburb </label>
                            <vs-input
                              v-model="entityDetail.registeredAddress.suburb"
                              class="w-full"
                              :name="`Rsuburb${countent}`"
                              data-vv-as="suburb"
                              v-validate="{required:(entityDetail.registeredAddress.autofill)?false:true}" @keyup="handleChange(countent,'registeredAddress')"
                              @keydown="clearAutoStatus(countent)" autocomplete/>
                            <span v-show="errors.has(`Rsuburb${countent}`)" class="text-danger text-sm">
                              {{ changeMessage( errors.first(`Rsuburb${countent}`), `Rsuburb${countent}`, 'street address' ) }}
                            </span>
                          </div>
                        </div>

                        <div class="flex mt-6 md:mt-10 flex-wrap">
                          <div class="input-grp">
                            <label class="vs-input--label w-full" :class="errors.has(`Rstate${countent}`) ? 'text-danger' : ''" >State </label>
                            <vs-select
                              autocomplete
                              v-model="entityDetail.registeredAddress.state"
                              class="w-full custom-w"
                              v-validate="{required:(entityDetail.registeredAddress.autofill)?false:true}"
                              :name="`Rstate${countent}`"
                              data-vv-as="state"
                              @keyup="handleChange(countent,'registeredAddress')" @keydown="clearAutoStatus(countent)">
                              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states"></vs-select-item>
                            </vs-select>
                            <span v-show="errors.has(`Rstate${countent}`)" class="text-danger text-sm">
                              {{changeMessage(errors.first(`Rstate${countent}`), `Rstate${countent}`, 'state')}}
                            </span>
                          </div>

                          <div class="input-grp">
                            <label class="vs-input--label w-full" :class="errors.has(`Rpostcode${countent}`) ? 'text-danger' : ''">Postcode </label>
                            <vs-input
                              v-model="entityDetail.registeredAddress.postCode"
                              class="w-full"
                              v-validate="{required:(entityDetail.registeredAddress.autofill)?false:true}"
                              :name="`Rpostcode${countent}`"
                              data-vv-as="postcode"
                              @keyup="handleChange(countent,'registeredAddress')"
                              @keydown="clearAutoStatus(countent)"
                              autocomplete/>
                            <span v-show="errors.has(`Rpostcode${countent}`)" class="text-danger text-sm">
                              {{changeMessage(errors.first(`Rpostcode${countent}`), `Rpostcode${countent}`, 'postcode')}}
                            </span>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>

              <div v-if="entityDetail.entityType">
                <!--  Trading address  -->
                <div class="mt-10 mb-4">
                <label class="text-base font-normal w-full block pb-2">Trading address</label>
                <div class="flex mb-5">
                  <vs-checkbox class="m-0 items-center flex font-light" v-model="entityDetail.isSameAsRegisteredAddress" :checked="false" @change="updateTradingAddress(countent)">
                  Same as registered address</vs-checkbox>
                </div>
                <div class="input-grp input-full">
                  <vue-google-autocomplete
                    v-if="entityDetail.isSameAsRegisteredAddress===false"
                    :ref="`tradingAddress${countent}`" :id="`tA${countent}`"
                    classname="w-full custom-w"
                    v-on:placechanged="getEmittedResponseTradingAddress"
                    placeholder="Start typing your address"
                    :country="['au']"
                    @change="handleChange(countent,'tradingAddress')"/>
                </div>
                <div>
                  <div class="flex mt-6 flex-wrap">
                    <div class="input-grp">
                      <label class="vs-input--label w-full">Apt, Suite, Unit, Building, Floor</label>
                      <vs-input v-model="entityDetail.tradingAddress.appartmentDetail" class="w-full" :disabled="entityDetail.isSameAsRegisteredAddress" @keyup="handleChange(countent,'tradingAddress')"/>
                    </div>
                  </div>

                  <div class="flex mt-6 flex-wrap">
                    <div class="input-grp">
                      <label class="vs-input--label w-full" :class="errors.has(`TradstreetAddress${countent}`) ? 'text-danger' : ''" >Street Address </label>
                      <vs-input
                        v-model="entityDetail.tradingAddress.streetAddress"
                        v-validate="{
                          required:(entityDetail.isSameAsRegisteredAddress || entityDetail.tradingAddress.autofill)?false:true
                        }"
                        :name="`TradstreetAddress${countent}`"
                        class="w-full"
                        :disabled="entityDetail.isSameAsRegisteredAddress"
                        @keydown="clearAutoStatus(countent,'T')"
                        @keyup="handleChange(countent,'tradingAddress')"/>
                      <span
                        v-show="errors.has(`TradstreetAddress${countent}`)"
                        class="text-danger text-sm">
                        {{changeMessage(errors.first(`TradstreetAddress${countent}`), `TradstreetAddress${countent}`, 'street address')}}
                      </span>
                    </div>

                    <div class="input-grp">
                      <label class="vs-input--label w-full" :class="errors.has(`Tradsuburb${countent}`) ? 'text-danger' : ''">Suburb </label>
                      <vs-input
                        v-model="entityDetail.tradingAddress.suburb"
                        class="w-full"
                        :name="`Tradsuburb${countent}`"
                        v-validate="{
                        required:(entityDetail.isSameAsRegisteredAddress || entityDetail.tradingAddress.autofill)?false:true
                        }"
                        :disabled="entityDetail.isSameAsRegisteredAddress"
                        @keyup="handleChange(countent,'tradingAddress')"
                        @keydown="clearAutoStatus(countent,'T')"/>
                      <span v-show="errors.has(`Tradsuburb${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`Tradsuburb${countent}`),
                          `Tradsuburb${countent}`,
                          'suburb'
                          )
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="flex mt-6 flex-wrap">
                    <div class="input-grp">
                      <label class="vs-input--label w-full" :class="errors.has(`Tradstate${countent}`) ? 'text-danger' : ''">State </label>
                      <vs-select
                        autocomplete
                        v-model="entityDetail.tradingAddress.state"
                        class="w-full custom-w"
                        v-validate="{
                          required:(entityDetail.isSameAsRegisteredAddress || entityDetail.tradingAddress.autofill)?false:true
                        }"
                        :name="`Tradstate${countent}`"
                        :disabled="entityDetail.isSameAsRegisteredAddress"
                        @keyup="handleChange(countent,'tradingAddress')"
                        @keydown="clearAutoStatus(countent,'T')">
                        <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item, index) in states"
                        ></vs-select-item>
                      </vs-select>
                      <span v-show="errors.has(`Tradstate${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`Tradstate${countent}`),
                          `Tradstate${countent}`,
                          'state'
                          )
                        }}
                      </span>
                    </div>

                    <div class="input-grp">
                      <label class="vs-input--label w-full" :class="errors.has(`Tradpostcode${countent}`) ? 'text-danger' : ''">Postcode </label>
                      <vs-input
                        v-model="entityDetail.tradingAddress.postCode"
                        class="w-full"
                        v-validate="{
                          required:(entityDetail.isSameAsRegisteredAddress || entityDetail.tradingAddress.autofill)?false:true
                        }"
                        :name="`Tradpostcode${countent}`"
                        :disabled="entityDetail.isSameAsRegisteredAddress"
                        @keyup="handleChange(countent,'tradingAddress')"
                        @keydown="clearAutoStatus(countent,'T')"/>
                      <span v-show="errors.has(`Tradpostcode${countent}`)" class="text-danger text-sm">
                        {{ errors.first(`postcode`) }}
                        {{
                          changeMessage(
                          errors.first(`Tradpostcode${countent}`),
                          `Tradpostcode${countent}`,
                          'postcode'
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="mt-10 flex flex-wrap"  v-if="entityDetail.entityType !='' && entityDetail.boCustomFields.length>0">
                <div v-for="(boFields, boIndexNumber) in entityDetail.boCustomFields" :key="boFields._id" class="input-grp">
                  <div class="mb-5" v-if="matchEntityWithBoAndCustomCf(countent,boIndexNumber)">
                    <label class="vs-input--label p-0 w-full" :class="errors.has(`${boFields.dataType}${countent}${boIndexNumber}`) ? 'text-danger' : ''">
                      {{boFields.label}}
                      <vs-tooltip v-if="boFields.tooltipText" :text="boFields.tooltipText" position="top" class="icon-tooltip">
                        <img class="icon-control" :src="tooltip" alt="tooltip" />
                      </vs-tooltip>
                    </label>

                    <money
                      v-if="boFields.dataType === 'currency'"
                      v-validate="{
                          required: boFields.isRequired ? true : false
                      }"
                      :name="`${boFields.dataType}${countent}${boIndexNumber}`"
                      v-model="boFields.value"
                      class="w-full vs-input--input"
                      v-bind="currencyMask"
                      @keyup="handleChangeCustom(countent,boIndexNumber)"/>

                    <vs-select
                      v-else-if="boFields.dataType == 'dropdown'"
                      v-validate="{ required: boFields.isRequired ? true : false, }"
                      class="selectExample w-full"
                      v-model="boFields.value"
                      :name="`${boFields.dataType}${countent}${boIndexNumber}`"
                      @change="handleChangeCustom(countent,boIndexNumber)">
                        <vs-select-item
                          :key="i"
                          :value="dropdown"
                          :text="dropdown"
                          v-for="(dropdown, i) in boFields.dropdownOptions"/>
                    </vs-select>

                    <vs-input
                      v-else
                      :type="boFields.dataType"
                      :name="`${boFields.dataType}${countent}${boIndexNumber}`"
                      v-validate="{required:(boFields.isRequired)?true:false,}"
                      class="w-full"
                      v-model="boFields.value"
                      @keyup="handleChangeCustom(countent,boIndexNumber)"/>
                    <span v-show="errors.has(`${boFields.dataType}${countent}${boIndexNumber}`)" class="text-danger text-sm">
                      {{changeMessage( errors.first(`${boFields.dataType}${countent}${boIndexNumber}`), `${boFields.dataType}${countent}${boIndexNumber}`, boFields.label.toLowerCase())}}
                    </span>
                  </div>
                </div>
              </div>


              <div v-if="entityDetail.entityType !=''">
                <div class="pt-5 pb-2">
                  <label class="text-base font-normal w-full" :class="errors.has(`entityActAsTrust${countent}`) ? 'text-danger' : ''">
                    Is this entity acting on behalf of a Trust?
                  </label>
                  <div class="pb-4">
                    <div class="mt-2 w-6/12 md:w-4/12 lg:w-2/12 flex justify-between">
                      <div class="flex justify-between">
                        <vs-radio
                          v-model="entityDetail.isEntityActAsTrust"
                          v-validate="'required|included:yes,no'"
                          :name="`entityActAsTrust${countent}`"
                          :vs-name="`entityActAsTrustBhef${countent}`"
                          vs-value="yes"
                          @change="handleChange(countent,'isEntityActAsTrust')">
                            <span class="text-base font-light">Yes</span>
                        </vs-radio>
                      </div>

                      <div class="flex justify-between">
                        <vs-radio
                          v-model="entityDetail.isEntityActAsTrust"
                          :name="`entityActAsTrust${countent}`"
                          :vs-name="`entityActAsTrustBhef${countent}`"
                          vs-value="no"
                          @change="handleChange(countent,'isEntityActAsTrust')">
                          <span class="text-base font-light">No</span>
                        </vs-radio>
                      </div>
                    </div>
                    <div v-show="errors.has(`entityActAsTrust${countent}`)" class="text-danger text-sm">
                      {{changeMessage( errors.first(`entityActAsTrust${countent}`), `entityActAsTrust${countent}`, 'entity acting on behalf')}}
                    </div>
                  </div>

                  <div  v-if="entityDetail.isEntityActAsTrust=='yes' || entityDetail.isEntityActAsTrust==true" class="flex items-center flex-wrap mt-4">
                    <div class="input-grp">
                      <label class="vs-input--label p-0 w-full" :class="errors.has(`Trustname${countent}`) ? 'text-danger' : ''">Trust name</label>
                      <vs-input
                        type="text"
                        :name="`Trustname${countent}`"
                        v-validate="'required'"
                        class="w-full"
                        v-model="entityDetail.trustName"
                        @keyup="handleChange(countent,'trustName')"/>
                      <span v-show="errors.has(`Trustname${countent}`)" class="text-danger text-sm">
                        {{changeMessage( errors.first(`Trustname${countent}`), `Trustname${countent}`, 'trust name')}}
                      </span>
                    </div>

                    <div class="input-grp">
                      <label
                        class="vs-input--label p-0 w-full"
                        :class="errors.first(`trustAbnField${countent}`) ? 'text-danger' : ''">ABN (if registered under this Trust)</label>
                      <vs-input
                        type="text"
                        class="w-full"
                        :name="`trustAbnField${countent}`"
                        v-model="entityDetail.trustAbn"
                        v-mask="['## ### ### ###']"
                        placeholder="Optional"
                        @keyup="trustAbnValidationWithSpace(countent)"/>
                      <span v-show="errors.has(`trustAbnField${countent}`)" class="text-danger text-sm">{{errors.first(`trustAbnField${countent}`)}}</span>
                    </div>

                    <div class="input-grp mt-5 mb-8">
                      <label :class="errors.has(`trustDeedFileVal${countent}`)?'text-danger':''" class="vs-input--label p-0 w-full">Trust deed</label>

                      <a v-if="entityDetail.hasFileUploaded" href="#">
                        {{typeof entityDetail.trustDeedFiles === 'string'
                          ? entityDetail.trustDeedFiles
                          : entityDetail.trustDeedFiles !== undefined
                          ? entityDetail.trustDeedFiles.name
                          : null}}
                      </a>

                      <S3FileGenerator
                        v-else
                        :document="typeof entityDetail.trustDeedFiles === 'string'
                          ? entityDetail.trustDeedFiles
                          : entityDetail.trustDeedFiles !== undefined
                          ? entityDetail.trustDeedFiles.name
                          : null"/>

                      <input
                        type="file"
                        class="hidden"
                        :name="`trustDeedFileVal${countent}`"
                        accept=".pdf"
                        :ref="`uploadImgInput${countent}`"
                        @change="updateCurrImg($event, countent)"
                        v-validate="{ required:(!entityDetail.trustDeedFiles) ? true : false, size: maxFileSize * 1024 }"/>
                      <input type="text" class="hidden" name="hasFileUploaded" v-model="entityDetail.hasFileUploaded"/>
                      <vs-button class="only-border-btn mt-2" size="small" style="width: 126px" v-round @click="uploadImgInput(countent)">Upload file</vs-button>
                      <span v-show="errors.has(`trustDeedFileVal${countent}`)" class="text-danger text-sm">
                        {{
                          changeMessage(
                          errors.first(`trustDeedFileVal${countent}`),
                          `trustDeedFileVal${countent}`,
                          'trust deed file'
                          )
                        }}
                      </span>
                    </div>
                  </div>

                  <div v-if="entityDetail.entityType=='Company'" class="mt-4 md:mt-8">

                    <h3 class="text-base font-normal">
                      Beneficial owners
                        <vs-tooltip
                          text="Beneficial owners are individuals that own 25% or more of the entity. This can be directly, such as through shareholdings, or indirectly, such as through another company’s ownership or a trust."
                          position="top"
                          class="icon-tooltip">
                            <img class="icon-control" :src="tooltip" alt="tooltip" />
                        </vs-tooltip>
                    </h3>
                    <hr class="line-hr mt-2" />

                    <p class="text-base instruction-style mt-6">
                      We need to send an online ID verification request to individuals who are beneficial owners of this entity. If you don’t have this information, you can still submit your application and we will contact you to proceed further.
                    </p>

                    <div class="pt-6 pb-8" v-for='(bodetail, boindex) in entityDetail.beneficialOwners' :key="boindex">
                      <feather-icon icon="Trash2Icon" svgClasses="w-5 h-5" @click="deleteBeneficialOwner(countent,boindex)" class="float-right" />
                      <h3 class="text-base font-normal mb-6 md:mb-10">Beneficial owner {{ parseInt(boindex) + 1 }}</h3>

                      <div class="input-grp mb-6 md:mb-10">
                        <label class="vs-input--label p-0 w-full" :class="errors.has(`BoFullname${countent}${boindex}`) ? 'text-danger' : ''">Full name </label>
                        <vs-input
                          type="text"
                          :name="`BoFullname${countent}${boindex}`"
                          v-validate="'required'"
                          class="w-full"
                          v-model="bodetail.fullName"
                          @keyup="handleChangeBeneficial(countent,boindex,'fullName')"/>
                        <span v-show="errors.has(`BoFullname${countent}${boindex}`)" class="text-danger text-sm">
                          {{changeMessage( errors.first(`BoFullname${countent}${boindex}`), `BoFullname${countent}${boindex}`, 'full name')}}
                        </span>
                      </div>

                      <div  class="flex items-center flex-wrap mt-3">
                        <div class="input-grp">
                          <label
                            class="vs-input--label p-0 w-full"
                            :class="errors.has(`BoMobilephone  ${countent}${boindex}`) ? 'text-danger' : ''">
                            Mobile phone number</label>
                          <vs-input
                            type="text"
                            :name="`BoMobilephone${countent}${boindex}`"
                            v-validate="'required|phoneFormat'"
                            class="w-full"
                            v-model="bodetail.mobileNumber"
                            v-mask="['#### ### ###']"
                            @keyup="handleChangeBeneficial(countent,boindex,'mobileNumber')"/>
                          <span v-show="errors.has(`BoMobilephone${countent}${boindex}`)" class="text-danger text-sm">
                            {{changeMessage(errors.first(`BoMobilephone${countent}${boindex}`),`BoMobilephone${countent}${boindex}`,'mobile phone number')}}
                          </span>
                        </div>

                        <div class="input-grp">
                          <label class="vs-input--label p-0 w-full" :class="errors.has(`BoEmailaddress${countent}${boindex}`) ? 'text-danger' : ''">Email address</label>
                          <vs-input
                            type="text"
                            :name="`BoEmailaddress${countent}${boindex}`"
                            v-validate="'required|email'"
                            class="w-full"
                            v-model="bodetail.email"
                            @keyup="handleChangeBeneficial(countent,boindex,'email')"/>
                          <span v-show="errors.has(`BoEmailaddress${countent}${boindex}`)" class="text-danger text-sm">
                            {{
                              changeMessage(
                              errors.first(`BoEmailaddress${countent}${boindex}`),
                              `BoEmailaddress${countent}${boindex}`,
                              'email address'
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div v-if="entityDetail.entityType=='Company'" class="w-full mt-8">
                    <a class="text-base mid-blue flex items-center" @click="addBeneficialOwnerInEntity(countent)">
                      <span class="pr-3">
                        <plus-icon size="1.5x" class="button-icon mid-blue"></plus-icon>
                      </span>
                      <span class="text-base mid-blue underline">Add beneficial owner</span>
                    </a>
                  </div>
                </div>
              </div>

          </div>

        </div>
      </vs-row>
    </div>

    <div class="w-full" style="padding-left: 38px !important">
      <hr v-if="entityList.length>1" class="line-hr mb-6 md:mb-10" />
      <vs-button @click="addEntity()" v-round size="medium">
        <span class="text-sm block"><span class="pr-2"><plus-icon size="1.5x" class="button-icon"></plus-icon></span>Add another entity</span>
      </vs-button>
    </div>
  </div>
</template>
<script>
const tooltip = require("@/assets/images/tooltip_dark.svg");
import { TheMask, mask } from "vue-the-mask";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import S3FileGenerator from "../../../onboarding/components/S3FileGenerator.vue"
import { Money } from "v-money";
import { EventBus } from "../../../../event-bus";
import { PlusIcon } from "vue-feather-icons";
import { ErrorBag } from 'vee-validate';
const bag = new ErrorBag();
export default {
    components:{
        PlusIcon,
        VueGoogleAutocomplete,
        TheMask,
        S3FileGenerator,
        Money
    },
      directives: { mask },
    props:{
        entityList:{
            type:Array
        },
        isComplete: { type: Object },
        businessOwnershipCustomFields:{
            type:Array
        },
        applicationStatus: {type:String}
    },
  data(){
      return{
        currencyMask: {
            prefix: "$ ",
            precision: 0,
            masked: false,
        },
        hasBeenCompleted: {},
        tooltip:tooltip,
        bag:bag,
        entityTypeList:[
            { name: 'Select entity' , value:""},
            { name: 'Company',value: 'Company'},
            { name: 'Individual  (sole trader)',value: 'Individual'},
        ],
        singleEntity:
        {
            entityType:"",
            companyName:"",
            abn: "",
            acn: "",
            fullName:"",
            emailAddress:"",
            mobileNumber:"",
            registeredAddress: {
                appartmentDetail:"",
                streetAddress:"",
                suburb: "",
                state:"",
                postCode: "",
            },
            isSameAsRegisteredAddress: false,
            tradingAddress: {
                appartmentDetail:"",
                streetAddress:"",
                suburb: "",
                state:"",
                postCode: "",
            },
            isEntityActAsTrust:null,
            trustName:"",
            trustAbn: "",
            trustDeedFiles:"",
            beneficialOwners: [],
            agentLicenseNumber:"",
            boCustomFields:this.businessOwnershipCustomFields
        },
        states: [
            { text: "NSW", value: "NSW" },
            { text: "ACT", value: "ACT" },
            { text: "QLD", value: "QLD" },
            { text: "VIC", value: "VIC" },
            { text: "NT", value: "NT" },
            { text: "SA", value: "SA" },
            { text: "WA", value: "WA" },
            { text: "TAS", value: "TAS" },
        ],
        newdata: false,
        payload: [],
        maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
      }
  },
  methods:{
    onchangeEntityType(key,value){
      this.checkIfCardIsComplete();
      this.$validator.reset();
      this.handleChange(key,'entityType')
    },

    addEntity(){
        this.entityList.push({
            entityType:"",
            companyName:"",
            abn: "",
            acn: "",
            fullName:"",
            emailAddress:"",
            mobileNumber:"",
            registeredAddress: {
                manualMode:false,
                appartmentDetail:"",
                streetAddress:"",
                suburb: "",
                state:"",
                postCode: "",
            },
            isSameAsRegisteredAddress: false,
            tradingAddress: {
                manualMode:false,
                appartmentDetail:"",
                streetAddress:"",
                suburb: "",
                state:"",
                postCode: "",
            },
            isEntityActAsTrust:null,
            trustName:"",
            trustAbn: "",
            trustDeedFiles:"",
            beneficialOwners: [],
            agentLicenseNumber:"",
            entityId: this.createUniqueId("E"),
            boCustomFields:JSON.parse(JSON.stringify(this.businessOwnershipCustomFields))
        });
        this.newdata = true;
        this.checkIfCardIsComplete();
    },

    checkPhoneNumber() {
        let mask = "#### ### ###";
        if (
            this.generalInformation.businessPhoneNumber &&
            this.generalInformation.businessPhoneNumber.length > 0
        ) {
            let numLength = this.generalInformation.businessPhoneNumber.length;
            let value1 = "";
            let value2 = "";
            if (numLength >= 2) {
                value1 = this.generalInformation.businessPhoneNumber.substring(0, 2);
                if (value1 === "13") {
                    value2 = this.generalInformation.businessPhoneNumber.substring(
                    0,
                    4
                );
                }
            }
            if (
                value1 === "02" ||
                value1 === "03" ||
                value1 === "07" ||
                value1 === "08"
            ) {
                mask = "## #### ####";
            } else {
                mask = "#### ### ###";
            }

            if (value1 === "13") {
                if (
                    parseInt(value2) >= 1300 ||
                    value2 === "130" ||
                    value2 === "13 0"
                ) {
                    mask = "#### ### ###";
                } else {
                    mask = "## ## ##";
                }
            }
        }
        return mask;
    },

    async getEmittedResponseRegisteredAddress(formattedPlace,place,indexOfLoop) {
      const id  = indexOfLoop.slice(2);
      this.entityList[id].registeredAddress.appartmentDetail = "";
      this.entityList[id].isSameAsRegisteredAddress = false;

      if (formattedPlace.street_number) {
        this.entityList[id].registeredAddress.streetNumber = formattedPlace.street_number;
      }

      if (formattedPlace.postal_code) {
        this.entityList[id].registeredAddress.postCode = formattedPlace.postal_code;
      }

      if (place.vicinity) {
        this.entityList[id].registeredAddress.suburb = place.vicinity;
      }

      if (formattedPlace.administrative_area_level_1) {
        this.entityList[id].registeredAddress.state = formattedPlace.administrative_area_level_1;
      }

      if (formattedPlace.route) {
        let street_address = "";

        for (const address of place.address_components) {
          if (address.types.includes("street_number")) {
            street_address += address.short_name + " ";
          }

          if (address.types.includes("route")) {
            street_address += address.long_name;
          }
        }

        this.entityList[id].registeredAddress.streetAddress =street_address;
        this.entityList[id].registeredAddress.manualMode = true;
        this.entityList[id].registeredAddress['autofill'] = true;
        const filterOutString = `, ${place.vicinity} ${formattedPlace.administrative_area_level_1}, ${formattedPlace.country}`;
      }
    },

    async getEmittedResponseTradingAddress(formattedPlace,place,indexOfLoop){
        const id  = indexOfLoop.slice(2);
        this.entityList[id].tradingAddress.appartmentDetail = "";

        if (formattedPlace.street_number) {
          this.entityList[id].tradingAddress.streetNumber = formattedPlace.street_number;
        }

        if (formattedPlace.postal_code) {
          this.entityList[id].tradingAddress.postCode = formattedPlace.postal_code;
        }

        if (place.vicinity) {
          this.entityList[id].tradingAddress.suburb = place.vicinity;
        }

        if (formattedPlace.administrative_area_level_1) {
          this.entityList[id].tradingAddress.state = formattedPlace.administrative_area_level_1;
        }

        if (formattedPlace.route) {
          let street_address = "";

          for (const address of place.address_components) {
            if (address.types.includes("street_number")) {
              street_address += address.short_name + " ";
            }

            if (address.types.includes("route")) {
              street_address += address.long_name;
            }
          }

          this.entityList[id].tradingAddress.streetAddress =street_address;
          this.entityList[id].tradingAddress.manualMode = true;
          this.entityList[id].tradingAddress['autofill'] = true;
          const filterOutString = `, ${place.vicinity} ${formattedPlace.administrative_area_level_1}, ${formattedPlace.country}`;
        }
    },

    updateTradingAddress(key) {
      if (this.entityList[key].isSameAsRegisteredAddress)
        this.entityList[key].tradingAddress = {
          ...this.entityList[key].registeredAddress,
        };
      else {
        this.entityList[key].tradingAddress.searchedText = "";
        this.entityList[key].tradingAddress.streetAddress = "";
        this.entityList[key].tradingAddress.suburb = "";
        this.entityList[key].tradingAddress.state = "";
        this.entityList[key].tradingAddress.postCode = "";
      }
      this.entityList[key].tradingAddress['autofill'] = false;
      this.checkIfCardIsComplete();
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    validateForm() {
      return this.$validator.validateAll();
    },

    deleteEntity(key){
        this.entityList.splice(key,1);
        EventBus.$emit("checkEntityList", this.entityList);
        this.checkIfCardIsComplete();
        this.payload = JSON.parse(JSON.stringify(this.entityList));
    },

    uploadImgInput(key) {
      this.$refs[`uploadImgInput${key}`][0].click();
    },

    updateCurrImg(input, key) {
      if(!input.target && input.target.files.length <= 0 && input.target.files[0]['type'] != "application/pdf"){
        this.errors.add({
          field: `file[${key}]`,
          msg: 'Invalid file type, Accept PDF only'
        });
        return false
      }

      let files = input.target.files[0];
      this.entityList[key].trustDeedFiles=  files;
      this.entityList[key].hasFileUploaded = true;
      this.errors.remove(`file[${key}]`);
      this.entityList[key]["fileuploadError"] = null;
      this.$validator.validateAll();
      this.checkIfCardIsComplete();
      this.handleChange(key, 'trustDeedFiles');
    },

    addBeneficialOwnerInEntity(key){
      this.entityList[key].beneficialOwners.push({
        fullName:"",
        mobileNumber: "",
        email: "",
      });
      this.checkIfCardIsComplete();
    },

    deleteBeneficialOwner(entityKey,bowKey){
      this.entityList[entityKey].beneficialOwners.splice(bowKey,1);
      this.checkIfCardIsComplete();
      this.payload = JSON.parse(JSON.stringify(this.entityList));
    },

    registerAddressManualModeOpen(key){
      this.entityList[key].registeredAddress= {
          appartmentDetail:"",
          streetAddress:"",
          suburb: "",
          state:"",
          postCode: "",
      };

      this.entityList[key].registeredAddress.manualMode = true;
      this.checkIfCardIsComplete();
    },

    registerAddressManualModeClose(key){
      this.entityList[key].registeredAddress.manualMode = false;
      this.checkIfCardIsComplete();
    },

    tradingAddressManualModeOpen(key){
      this.entityList[key].tradingAddress= {
        appartmentDetail:"",
        streetAddress:"",
        suburb: "",
        state:"",
        postCode: "",
      };

      this.entityList[key].tradingAddress.manualMode = true;
      this.checkIfCardIsComplete();
    },

    tradingAddressManualModeClose(key){
      this.entityList[key].tradingAddress.manualMode = false;
      this.checkIfCardIsComplete();
    },

    checkIfCardIsComplete() {
      let statuses = [];
      let status = true;
      let statusesOfBo = [];
      let statusesOfBofield = [];

      // TODO - refactor this monstrosity
      for (let index = 0; index < this.entityList.length; index++) {
          statuses[index] =
            this.entityList[index].entityType.length > 0 &&

            (
              ( this.entityList[index].entityType === 'Company')?
                (
                  this.entityList[index].companyName.length > 0 &&
                  this.entityList[index].acn.length == 11
                ):
                (
                  this.entityList[index].fullName.length > 0 &&
                  this.entityList[index].emailAddress.length > 0   &&
                  this.entityList[index].mobileNumber.length > 0
                )
            ) &&

            this.entityList[index].registeredAddress.streetAddress.length > 0 &&
            this.entityList[index].registeredAddress.suburb.length > 0 &&
            this.entityList[index].registeredAddress.state.length > 0 &&
            this.entityList[index].registeredAddress.postCode.length > 0 &&

            this.entityList[index].tradingAddress.streetAddress.length > 0 &&
            this.entityList[index].tradingAddress.suburb.length > 0 &&
            this.entityList[index].tradingAddress.state.length > 0 &&
            this.entityList[index].tradingAddress.postCode.length > 0 &&

            (
                ( this.entityList[index].isEntityActAsTrust=='yes') ?
                    (
                      ((typeof this.entityList[index].trustDeedFiles === "string" && this.entityList[index].trustDeedFiles.length > 0) || this.entityList[index].hasFileUploaded === true) &&
                        this.entityList[index].trustName.length >0
                    ):(( this.entityList[index].isEntityActAsTrust =="no" ))
            )

        for (let bindex = 0; bindex < this.entityList[index].beneficialOwners.length; bindex++) {
          statusesOfBo[bindex] =
          this.entityList[index].beneficialOwners[bindex].fullName.length>0 &&
          this.entityList[index].beneficialOwners[bindex].mobileNumber.length>0 &&
          this.entityList[index].beneficialOwners[bindex].email.length>0
        }

        for (let bofieldIndex = 0; bofieldIndex < this.entityList[index].boCustomFields.length; bofieldIndex++) {
          if (this.entityList[index].boCustomFields[bofieldIndex].isRequired && this.matchEntityWithBoAndCustomCf(index,bofieldIndex)) {
            if (this.entityList[index].boCustomFields[bofieldIndex].dataType==='currency') {
              statusesOfBofield[bofieldIndex] = this.entityList[index].boCustomFields[bofieldIndex].value > 0
            } else {
              statusesOfBofield[bofieldIndex] = this.entityList[index].boCustomFields[bofieldIndex].value.length > 0
            }
          } else {
            statusesOfBofield[bofieldIndex] =  true;
          }
        }
      }

    if(this.entityList.length>0){
      for (let index = 0; index < statuses.length; index++) {
        if (!statuses[index]) {
          status = false;
          break;
        }
      }

      for (let boindex = 0; boindex < statusesOfBo.length; boindex++) {
        if (!statusesOfBo[boindex]) {
          status = false;
          break;
        }
      }

      for (let boCFindex = 0; boCFindex < statusesOfBofield.length; boCFindex++) {
        if (!statusesOfBofield[boCFindex]) {
          status = false;
          break;
        }
      }
    } else {
      status = false;
    }
      this.isComplete.businessWonershipStatus = status;
      this.$emit("businessWonershipComplete");
      this.$emit("checkDirty", true);
    },
    changeName(entity) {
        EventBus.$emit("addEntityList", {entity,newdata:this.newdata});
        this.newdata = false;
        this.checkIfCardIsComplete();
    },
    createUniqueId(character) {
      let firstBits = Math.floor(1000 + Math.random() * 9000);
      let secondBits = Math.floor(1000 + Math.random() * 9000);
      let uniqueId = `${character}-${firstBits}-${secondBits}`;
      return uniqueId;
    },
    matchEntityWithBoAndCustomCf(boIndex, cfIndex){
      const boEntityType  = this.entityList[boIndex].entityType;
      const cfEntityType = this.businessOwnershipCustomFields[cfIndex].entity;

      if(cfEntityType.includes(boEntityType.toLowerCase())){
        return true
      }

      return false;
    },
    clearAutoStatus(key,type=null){
      if(type=="T"){
        this.entityList[key].tradingAddress['autofill'] = false;
      }else{
        this.entityList[key].registeredAddress['autofill'] = false;
      }
    },
    handleChange(index,flag){
      try{
        if(this.applicationStatus === 'Info Requested'){
          let changedlabel = `businessOwnership[${index}]${flag}`
          const data = {
            label: changedlabel,
            oldValue: this.payload[index]
            ? (flag == 'registeredAddress' || flag == 'tradingAddress')
              ? JSON.stringify(this.payload[index][flag])
              : this.payload[index][flag]
            : "",
            newValue: (flag == 'registeredAddress' || flag == 'tradingAddress')
            ? JSON.stringify(this.entityList[index][flag])
            : typeof this.entityList[index][flag] == "object" ? this.entityList[index][flag].name : this.entityList[index][flag]
          }
          this.$emit("resubmit", data);
        }
        this.checkIfCardIsComplete();
      }catch(e){
        console.log(e)
      }
    },

    handleChangeCustom(index,customIndex){
      try{
        if(this.applicationStatus === 'Info Requested'){
          let changedlabel = `businessOwnership[${index}]boCustomFields[${customIndex}]`
          const data = {
            label: changedlabel,
            oldValue: this.payload[index] && this.payload[index].boCustomFields[customIndex] ? this.payload[index].boCustomFields[customIndex].value : "",
            newValue: this.entityList[index].boCustomFields[customIndex].value
          }
          this.$emit("resubmit", data);
        }
        this.checkIfCardIsComplete();
      }catch(e){
        console.log(e)
      }
    },

    handleChangeBeneficial(index,customIndex,flag){
      try{
        if(this.applicationStatus === 'Info Requested'){
          let changedlabel = `businessOwnership[${index}]beneficialOwners[${customIndex}]${flag}`
          const data = {
            label: changedlabel,
            oldValue: this.payload[index] && this.payload[index].beneficialOwners[customIndex] ? this.payload[index].beneficialOwners[customIndex][flag] : "",
            newValue: this.entityList[index].beneficialOwners[customIndex][flag]
          }
          this.$emit("resubmit", data);
        }

        this.checkIfCardIsComplete();
      }catch(e){
        console.log(e)
      }
    },

    abnValidationWithSpace(index,passedField) {
      let length = this.entityList[index].abn.split(" ").join("").length;
      const abnCheck = (this.entityList[index].abn !== undefined && this.entityList[index].abn.split(" ").join("").length !== 11);

      if (abnCheck && length > 0) {
        bag.add({
          field: passedField,
          msg: 'The ABN field must be 11 digits'
        });
      }else{
        bag.remove(passedField)
      }

      this.handleChange(index,'abn')

    },

    trustAbnValidationWithSpace(index) {
      let length = this.entityList[index].trustAbn.split(" ").join("").length;
      const trustAbnCheck =  (
        this.entityList[index].trustAbn !== undefined &&
        this.entityList[index].trustAbn.split(" ").join("").length !== 11
      );

      if(trustAbnCheck && length>0){
        this.errors.add({
          field: `trustAbnField${index}`,
          msg: 'The ABN field must be 11 digits'
        });
      } else {
        this.errors.remove(`trustAbnField${index}`)
      }

      this.handleChange(index,'trustAbn')
      return false;
    },

    acnValidationWithSpace(index) {
      let length = this.entityList[index].acn.split(" ").join("").length;
      const trustAcnCheck =  (
        this.entityList[index].acn !== undefined &&
        this.entityList[index].acn.split(" ").join("").length !== 9
      );

      if (trustAcnCheck && length>0) {
        this.errors.add({
            field: `ACN${index}`,
            msg: 'The ACN field must be 9 digits'
        });

        this.checkIfCardIsComplete();
      } else {
        this.errors.remove(`ACN${index}`);
        this.checkIfCardIsComplete();
      }

      this.handleChange(index,'acn')

      return false;
    },
  },

  created(){
        this.hasBeenCompleted = this.isComplete;
        this.checkIfCardIsComplete();
        this.payload = JSON.parse(JSON.stringify(this.entityList));
  },

  updated(){
    this.checkIfCardIsComplete();
  },

  watch: {
    isFormChanged(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormChanged);
      }
    },
  },

}
</script>
