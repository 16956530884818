<template>
  <div>
    <!-- onboarding custom fields -->
    <vs-row v-if="customFieldsArray && customFieldsArray.length > 0">
      <vs-col
        vs-w="6"
        vs-lg="6"
        vs-sm="12"
        vs-xs="12"
        class="mb-4 mt-4"
        v-for="(text, index) in customFieldsArray"
        :key="index"
      >
        <vs-row>
          <div class="input-grp">
            <label
              class="vs-input--label w-full p-0"
              :class="
                errors.has(`customFields.${text.label}`) ? 'text-danger' : ''
              "
              >{{ text.label }}
              <vs-tooltip
                v-if="text.tooltipText"
                :text="text.tooltipText"
                position="top"
                class="icon-tooltip"
              >
                <img class="icon-control" :src="tooltip" alt="tooltip" />
              </vs-tooltip>
            </label>
            <div v-if="text.dataType == 'dropdown'">
              <vs-select
                v-validate="{
                  required: text.isRequired ? true : false,
                }"
                data-vv-scope="customFields"
                class="selectExample w-full"
                v-model="text.value"
                :name="`${text.label}`"
                :placeholder="checkPlaceholder(text.isRequired)"
                @keyup="handleChange(index)"
              >
                <vs-select-item
                  :key="i"
                  :value="dropdown"
                  :text="dropdown"
                  v-for="(dropdown, i) in text.dropdownOptions"
                />
              </vs-select>
              <span
                class="text-danger text-sm"
                v-if="errors.has(`customFields.${text.label}`)"
                >{{ errors.first(`customFields.${text.label}`) }}</span
              >
            </div>

            <div v-else-if="text.dataType === 'currency'">
              <v-money-spinner
                data-vv-scope="customFields"
                :name="`${text.label}`"
                v-model="text.value"
                v-bind="config"
                v-validate="`${text.isRequired ? 'required|customCurrency' : ''}`"
                :placeholder="checkPlaceholder(text.isRequired)"
                class="w-full currency-input"
                @change="handleChange(index)"></v-money-spinner>
              <span
                class="text-danger text-sm"
                v-show="errors.has(`customFields.${text.label}`)"
                >{{ errors.first(`customFields.${text.label}`) }}
              </span>
            </div>
            <div v-else>
              <vs-input
                v-if="text.dataType !== `date`"
                v-validate="{
                  required: text.isRequired ? true : false,
                }"
                data-vv-scope="customFields"
                :type="text.dataType"
                class="w-full"
                size="large"
                :name="`${text.label}`"
                v-model="text.value"
                :placeholder="checkPlaceholder(text.isRequired)"
                @keyup="handleChange(index)"
                @change="handleChange(index)"
              />
              <div>
              <span
                class="text-danger text-sm"
                v-if="
                  errors.has(`customFields.${text.label}`) &&
                  text.dataType !== `date`
                "
                >{{ errors.first(`customFields.${text.label}`) }}
              </span>
              </div>

              <vs-input
                v-if="text.dataType == `date`"
                v-validate="{
                  required: text.isRequired ? true : false,
                }"
                data-vv-scope="customFields"
                :type="text.dataType"
                class="w-full"
                size="large"
                :name="`${text.label}`"
                v-model="text.value"
                @change="handleChange(index)"
              />
              <div>
              <span
                class="text-danger text-sm"
                v-if="
                  errors.has(`customFields.${text.label}`) &&
                  text.dataType == `date`
                "
                >{{ errors.first(`customFields.${text.label}`) }}
              </span>
              </div>
            </div>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { Money } from "v-money";
const tooltip = require("@/assets/images/tooltip_dark.svg");
import { Validator } from "vee-validate";

export default {
  name: "BusinessProfile",
  components: { Money },
  props: {
    businessProfile: { type: Object },
    isComplete: { type: Object },
    customFieldsArray: { type: Array },
    applicationStatus: { type: String },
  },
  data() {
    return {
      config: {
        spinner: false,
        prefix: "$ ",
        suffix: "",
        precision: 0,
        decimal: '.',
        thousands: ',',
        masked: false,
      },
      payload: [],
      hasBeenCompleted: {},
      tooltip: tooltip,
    };
  },
  created() {
    this.payload = JSON.parse(JSON.stringify(this.customFieldsArray));
    this.hasBeenCompleted = this.isComplete;
    this.checkIfCardIsComplete();
    Validator.extend("customCurrency", {
      getMessage: (field) => "Currency input is required",
      validate(value) {
        return new Promise((resolve) => {
          resolve({
            valid: value <= 0 ? false : !!value,
            data: value > 0 ? undefined : { message: "Not this value" },
          });
        });
      },
    });
  },
  methods: {
    checkIfCardIsComplete() {
      let statusesOfCffield = [];
      let filteredCFArray = this.customFieldsArray.filter((element) => {
        return (
          element.isRequired == true &&
          (!element.value || element.value.length === 0)
        );
      });
      if (filteredCFArray.length == 0) {
        this.hasBeenCompleted.businessProfileStatus = true;
      }
      if (filteredCFArray.length > 0) {
        this.hasBeenCompleted.businessProfileStatus = false;
      }
      for (let cfIndex = 0; cfIndex < filteredCFArray.length; cfIndex++) {
        if (filteredCFArray[cfIndex].dataType === "currency") {
          statusesOfCffield[cfIndex] = filteredCFArray[cfIndex].value > 0;
        } else {
          statusesOfCffield[cfIndex] =
            filteredCFArray[cfIndex].value.length > 0;
        }
        this.hasBeenCompleted.businessProfileStatus = statusesOfCffield[cfIndex];
      }
    },

    validateForm() {
      return this.$validator.validateAll();
    },

    auto_grow(elem) {
      var element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
      this.$emit("profileHeight");
    },
    checkPlaceholder(value) {
      if (!value) {
        return "Optional";
      }
      return;
    },
    handleChange(index){
      if(this.applicationStatus === 'Info Requested'){
        let changedlabel = `onboardingCustomFields[${index}]${this.customFieldsArray[index].label}`
        const data = {
          label: changedlabel,
          oldValue: this.payload[index].value,
          newValue: this.customFieldsArray[index].value
        }
        this.$emit("resubmit", data);
      }
      this.checkIfCardIsComplete();
    }
  },
  watch: {
    //required isFormChanged rather than isFormDirty since dirty is being tracked true even if value is not changed
    isFormChanged(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormChanged);
      }
    },
  },
};
</script>