<template>
  <div>
    <vs-row class="mb-8">
      <vs-col vs-w="12">
        <div class="flex items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.54657 0.968004C9.82273 0.788497 10.1787 0.788497 10.4549 0.968004L18.7882 6.38467C19.0247 6.53839 19.1674 6.80131 19.1674 7.08337V12.9167C19.1674 13.1988 19.0247 13.4617 18.7882 13.6154L10.4549 19.0321C10.1787 19.2116 9.82273 19.2116 9.54657 19.0321L1.21323 13.6154C0.976738 13.4617 0.834056 13.1988 0.834056 12.9167V7.08337C0.834056 6.80131 0.976738 6.53839 1.21323 6.38467L9.54657 0.968004ZM2.50072 7.53561V12.4645L10.0007 17.3395L17.5007 12.4645V7.53561L10.0007 2.66061L2.50072 7.53561Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0007 12.0834C10.461 12.0834 10.8341 12.4565 10.8341 12.9167V18.3334C10.8341 18.7936 10.461 19.1667 10.0007 19.1667C9.54049 19.1667 9.16739 18.7936 9.16739 18.3334V12.9167C9.16739 12.4565 9.54049 12.0834 10.0007 12.0834Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.984696 6.60549C1.24862 6.22845 1.76823 6.13675 2.14527 6.40068L10.0007 11.8995L17.8562 6.40068C18.2332 6.13675 18.7528 6.22845 19.0167 6.60549C19.2807 6.98253 19.189 7.50214 18.8119 7.76607L10.4786 13.5994C10.1917 13.8003 9.80977 13.8003 9.52284 13.5994L1.1895 7.76607C0.812463 7.50214 0.720767 6.98253 0.984696 6.60549Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.52284 6.40068C9.80977 6.19983 10.1917 6.19983 10.4786 6.40068L18.8119 12.234C19.189 12.4979 19.2807 13.0176 19.0167 13.3946C18.7528 13.7716 18.2332 13.8633 17.8562 13.5994L10.0007 8.10059L2.14527 13.5994C1.76823 13.8633 1.24862 13.7716 0.984696 13.3946C0.720767 13.0176 0.812463 12.4979 1.1895 12.234L9.52284 6.40068Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0007 0.833374C10.461 0.833374 10.8341 1.20647 10.8341 1.66671V7.08337C10.8341 7.54361 10.461 7.91671 10.0007 7.91671C9.54049 7.91671 9.16739 7.54361 9.16739 7.08337V1.66671C9.16739 1.20647 9.54049 0.833374 10.0007 0.833374Z"
              fill="#BDBDBD"
            />
          </svg>
          <h3 class="text-xl pl-4">Payment options</h3>
        </div>

        <hr class="line-hr mb-8 mt-2" />
      </vs-col>
      <div class="product-form">
        <vs-col vs-w="12" class="mb-8">
          <p class="mb-0 text-base instruction-style" v-if="!errors.has('payNowN')">
            Confirm the payment options to enable on your account.
          </p>
          <span v-show="errors.has('payNowN') || errors.has('payLaterN')" class="text-danger text-sm">
            Select the payment options you'd like to enable.
          </span>
        </vs-col>
        <vs-col vs-w="12">
          <vs-row class="paymentOpt">
            <div v-if="this.rawProducts.includes('pay-now')" class="radio-card selectcard justify-start" v-bind:class="{ active: product.productOption.includes('pay-now') }">
              <vs-row class="flex justify-between">
                <div class="radio-label text-xl font-normal">Pay Now</div>
                <div class="flex justify-center">
                  <vs-checkbox v-model="product.productOption" vs-value="pay-now" vs-name="payNow" name="payNowN" style="margin-right: -.5rem !important" @change="checkIfCardIsComplete"></vs-checkbox>
                </div>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <p class="radio-info">
                    Merchant services and online payments for my customers
                  </p>
                </vs-col>
              </vs-row>
            </div>

            <div v-if="this.rawProducts.includes('pay-later')" class="radio-card selectcard justify-start" v-bind:class="{ active: product.productOption.includes('pay-later') }">
              <vs-row class="flex justify-between">
                <div class="radio-label text-xl font-normal">Pay Later</div>
                <div class="flex justify-center">
                  <vs-checkbox v-model="product.productOption" vs-value="pay-later" vs-name="paylater" name="payLaterN" style="margin-right: -.5rem !important" @change="checkIfCardIsComplete"></vs-checkbox>
                </div>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <p class="radio-info">
                    Payment deferral options and interest-free payment plans for
                    my customers
                  </p>
                </vs-col>
              </vs-row>
            </div>
          </vs-row>
        </vs-col>
      </div>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-now')" id="payNowArea">
      <vs-col vs-w="12">
        <div class="flex items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.54657 0.968004C9.82273 0.788497 10.1787 0.788497 10.4549 0.968004L18.7882 6.38467C19.0247 6.53839 19.1674 6.80131 19.1674 7.08337V12.9167C19.1674 13.1988 19.0247 13.4617 18.7882 13.6154L10.4549 19.0321C10.1787 19.2116 9.82273 19.2116 9.54657 19.0321L1.21323 13.6154C0.976738 13.4617 0.834056 13.1988 0.834056 12.9167V7.08337C0.834056 6.80131 0.976738 6.53839 1.21323 6.38467L9.54657 0.968004ZM2.50072 7.53561V12.4645L10.0007 17.3395L17.5007 12.4645V7.53561L10.0007 2.66061L2.50072 7.53561Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0007 12.0834C10.461 12.0834 10.8341 12.4565 10.8341 12.9167V18.3334C10.8341 18.7936 10.461 19.1667 10.0007 19.1667C9.54049 19.1667 9.16739 18.7936 9.16739 18.3334V12.9167C9.16739 12.4565 9.54049 12.0834 10.0007 12.0834Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.984696 6.60549C1.24862 6.22845 1.76823 6.13675 2.14527 6.40068L10.0007 11.8995L17.8562 6.40068C18.2332 6.13675 18.7528 6.22845 19.0167 6.60549C19.2807 6.98253 19.189 7.50214 18.8119 7.76607L10.4786 13.5994C10.1917 13.8003 9.80977 13.8003 9.52284 13.5994L1.1895 7.76607C0.812463 7.50214 0.720767 6.98253 0.984696 6.60549Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.52284 6.40068C9.80977 6.19983 10.1917 6.19983 10.4786 6.40068L18.8119 12.234C19.189 12.4979 19.2807 13.0176 19.0167 13.3946C18.7528 13.7716 18.2332 13.8633 17.8562 13.5994L10.0007 8.10059L2.14527 13.5994C1.76823 13.8633 1.24862 13.7716 0.984696 13.3946C0.720767 13.0176 0.812463 12.4979 1.1895 12.234L9.52284 6.40068Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0007 0.833374C10.461 0.833374 10.8341 1.20647 10.8341 1.66671V7.08337C10.8341 7.54361 10.461 7.91671 10.0007 7.91671C9.54049 7.91671 9.16739 7.54361 9.16739 7.08337V1.66671C9.16739 1.20647 9.54049 0.833374 10.0007 0.833374Z"
              fill="#BDBDBD"
            />
          </svg>
          <h3 id="paynowProduct" class="text-xl pl-4">
            Pay Now payment methods
          </h3>
        </div>
        <hr class="line-hr mt-2 mb-8" />
      </vs-col>

      <vs-col vs-w="12" class="product-form">
        <vs-row class="mb-8">
          <p class="mb-0 text-base instruction-style" v-if="!errors.has('payNowPaymentMethods')">
            Confirm the payment methods to enable on your account.
          </p>

          <span v-show="errors.has('payNowPaymentMethods')" class="text-danger text-sm">
            Select the payment options you'd like to enable on your account.
          </span>
        </vs-row>
        <vs-row class="paymentOpt">
          <div v-for="(paynowPlan, key) in product.payNowPaymentMethods" :key="key" class="radio-card big" v-bind:class="{ active: paynowPlan.isChecked == 1 }">
            <vs-row class="flex justify-between">
              <div class="flex items-center">
                <h4 class="radio-label pr-4 m-0 font-normal" v-bind:class="{ activeHeader: paynowPlan.isChecked == 1 }">
                  {{ paynowPlan.title }}
                </h4>
                <S3ImageGenerator :document="paynowPlan.icon" :is-base64="false" :custom-class="'card-payment-method'" />
              </div>

              <div class="flex justify-center">
                <vs-checkbox
                  @change="checkIfCardIsComplete('pay-now')"
                  v-model="paynowPlan.isChecked"
                  vs-value="1"
                  vs-name="mastercard"
                  class="m-0"
                  style="margin-right: -1rem !important"
                ></vs-checkbox>
              </div>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div class="mb-8">
                  <p class="w-full mb-2 text-base" style="color: #828282">
                    Merchant service fee
                  </p>
                  <h6>{{ `${addZeroes(parseFloat(paynowPlan.msf))} %` }}</h6>
                </div>

                <div class="mb-2">
                  <p class="w-full mb-0 text-base font-light" style="color: #828282">
                    Card surcharge <span class="mid-blue">*</span>
                    <vs-tooltip
                      active
                      text="A credit card surcharge is the payment processing fee that will be charged to customers paying by card. The surcharge is to recover the cost of processing credit or debit card transactions. You cannot charge a customer more than it costs you to process the transaction. If the amount is less than merchant service fee, your business will absorb the difference."
                      position="top"
                      class="icon-tooltip"
                    >
                    </vs-tooltip>
                  </p>
                  <money
                    id="surcharge"
                    v-bind="money2"
                    v-model.lazy="paynowPlan.surcharge"
                    :name="`surcharge[${key}]`"
                    @keyup.native="handleChange(key,'surcharge')"
                    @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
                  ></money>
                  <span v-show="errors.has(`surcharge[${key}]`)" class="text-danger text-sm">
                    {{ changeMessage(errors.first(`surcharge[${key}]`), `surcharge[${key}]`, "surcharge") }}
                  </span>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-later')" class="mt-8" id="paylaterArea">
      <vs-col vs-w="12">
        <div class="flex items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.54657 0.968004C9.82273 0.788497 10.1787 0.788497 10.4549 0.968004L18.7882 6.38467C19.0247 6.53839 19.1674 6.80131 19.1674 7.08337V12.9167C19.1674 13.1988 19.0247 13.4617 18.7882 13.6154L10.4549 19.0321C10.1787 19.2116 9.82273 19.2116 9.54657 19.0321L1.21323 13.6154C0.976738 13.4617 0.834056 13.1988 0.834056 12.9167V7.08337C0.834056 6.80131 0.976738 6.53839 1.21323 6.38467L9.54657 0.968004ZM2.50072 7.53561V12.4645L10.0007 17.3395L17.5007 12.4645V7.53561L10.0007 2.66061L2.50072 7.53561Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0007 12.0834C10.461 12.0834 10.8341 12.4565 10.8341 12.9167V18.3334C10.8341 18.7936 10.461 19.1667 10.0007 19.1667C9.54049 19.1667 9.16739 18.7936 9.16739 18.3334V12.9167C9.16739 12.4565 9.54049 12.0834 10.0007 12.0834Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.984696 6.60549C1.24862 6.22845 1.76823 6.13675 2.14527 6.40068L10.0007 11.8995L17.8562 6.40068C18.2332 6.13675 18.7528 6.22845 19.0167 6.60549C19.2807 6.98253 19.189 7.50214 18.8119 7.76607L10.4786 13.5994C10.1917 13.8003 9.80977 13.8003 9.52284 13.5994L1.1895 7.76607C0.812463 7.50214 0.720767 6.98253 0.984696 6.60549Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.52284 6.40068C9.80977 6.19983 10.1917 6.19983 10.4786 6.40068L18.8119 12.234C19.189 12.4979 19.2807 13.0176 19.0167 13.3946C18.7528 13.7716 18.2332 13.8633 17.8562 13.5994L10.0007 8.10059L2.14527 13.5994C1.76823 13.8633 1.24862 13.7716 0.984696 13.3946C0.720767 13.0176 0.812463 12.4979 1.1895 12.234L9.52284 6.40068Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0007 0.833374C10.461 0.833374 10.8341 1.20647 10.8341 1.66671V7.08337C10.8341 7.54361 10.461 7.91671 10.0007 7.91671C9.54049 7.91671 9.16739 7.54361 9.16739 7.08337V1.66671C9.16739 1.20647 9.54049 0.833374 10.0007 0.833374Z"
              fill="#BDBDBD"
            />
          </svg>
          <h3 id="paylaterProduct" class="text-xl pl-4">Pay Later products</h3>
        </div>
        <hr class="line-hr mt-2 mb-8" />
      </vs-col>

      <vs-col vs-w="12" class="product-form">
        <vs-row class="mb-8">
          <p class="mb-0 text-base instruction-style" v-if="!errors.has('payLaterPaymentMethods')">
            Confirm the Pay Later products to enable on your account.
          </p>

          <span v-show="errors.has('payLaterPaymentMethods')" class="text-danger text-sm">
            Select the Pay Later options you would like to enable.
          </span>
        </vs-row>
        <vs-row class="paymentOpt">
          <div v-for="(payLaterPlan, key) in product.payLaterProducts" :key="key" class="radio-card big" v-bind:class="{ active: payLaterPlan.isChecked == 1 || payLaterPlan.isChecked }">
            <vs-row>
              <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                <h4 class="text-xl font-normal mb-0" v-bind:class="{ activeHeader: payLaterPlan.isChecked == 1 || payLaterPlan.isChecked }">
                  {{ payLaterPlan.productName }}
                </h4>
              </vs-col>
              <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-end">
                <vs-checkbox
                  @change="checkIfCardIsComplete('pay-later')"
                  v-model="payLaterPlan.isChecked"
                  vs-name="mastercard"
                  class="items-start"
                  style="margin-right: -1rem !important"
                ></vs-checkbox>
              </vs-col>

              <p class="text-base font-light mb-1 mt-2">
                {{ payLaterPlan.productDescription }}
              </p>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                <div>
                  <p class="text-base font-light mb-0">
                    Service fee:
                    <span style="color: #05053f" class="text-base font-light ml-2">{{ !isNaN(payLaterPlan.msf) ? `${addZeroes(parseFloat(payLaterPlan.msf))} %` : payLaterPlan.msf }}</span>
                  </p>
                  <p v-if="payLaterPlan.feeModel == 'MSF_INCLUDED'" class="w-full text-base mt-2 font-light" style="whitespace: nowarp; color: #828282">
                    (payable by Merchant)
                  </p>
                  <!-- MSF to customer (MSF-C) -->
                  <p v-else class="w-full text-base mt-2 font-light" style="whitespace: nowarp; color: #828282">
                    (payable by Customer)
                  </p>
                  <!-- MSF to customer (MSF-C) -->
                  <vs-button v-round class="w-full only-border-btn mt-3" @click="openPlanConfigurationPopup(payLaterPlan, key)">Example $1,000 payment</vs-button>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- popup starts-->
    <vs-popup id="holamundo" class="config-popup" :class="activePlan.productType == 'B2C_BNPL' ? 'paymentSummaryPopup' : ''" :title="`Example  $ 1,000.00 payment`" :active.sync="popupActivo" @close="closePopup">
      <div class="w-full" v-if="activePlan.productType == 'B2C_BNPL'">
        <vs-col vs-w="12">
          <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start gray">
            <div class="flex flex-col justify-center items-center">
              <img :src="update" alt="update" />
              <span class="dark-blue text-xs mt-2"> Every</span>
              <span class="dark-blue text-xs">{{ `${everyNumber} ${weekOrMonthOrDay}` }}</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="playCircle" alt="playcircle" /><span class="dark-blue text-xs mt-2">Starts on</span>
              <span class="dark-blue text-xs">acceptance date</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="stopCircle" alt="stopcircle" />
              <span class="dark-blue text-xs mt-2"> Ends after</span>
              <span class="dark-blue text-xs">{{ `${this.activePlan.noOfPayments} payments` }}</span>
            </div>
          </vs-row>

          <vs-table :data="paymentSchedules" class="payment-schedule-table mt-3">
            <template slot="thead">
              <vs-th class="text-base"><span class="font-medium" style="color:#757575;">Payment date</span></vs-th>
              <vs-th class="text-base"><span class="font-medium" style="color:#757575;">Amount</span></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.value">
                  {{ formatScheduleDate(tr.value) == formatScheduleDate(new Date()) ? "Today" : formatScheduleDate(tr.value) }}
                </vs-td>
                <vs-td class="sec-row-payment" :data="tr.label">
                  {{ tr.label !== "0.00" ? `$ ${tr.label}` : "-" }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="vs-table--content">
            <vs-row class="flex justify-between">
              <span class="text-lg dark-blue">Customer pays</span>
              <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
            </vs-row>
            <vs-row class="mt-4">
              <span class="text-lg dark-blue">Merchant receives</span>
              <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
            </vs-row>
          </div>
        </vs-col>
      </div>

      <div class="w-full" v-if="activePlan.productType == 'B2C_BNPO' || activePlan.productType == 'B2C_BNPO_NC'">
        <vs-col vs-w="12">
          <div class="gray mb-5 items-center flex">
            <div><img :src="calenderImage" alt="calenderImage" /></div>
            <p class="dark-blue text-base font-normal ml-4">
              {{ productText }}
            </p>
          </div>
          <div class="b2c">
            <vs-row class="flex justify-between">
              <span class="text-lg dark-blue">Customer pays</span>
              <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
            </vs-row>
            <vs-row class="mt-4">
              <span class="text-lg dark-blue">Merchant receives</span>
              <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
            </vs-row>
          </div>
        </vs-col>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const tooltip = require("@/assets/images/tooltip-blue.svg");
const calenderImage = require("@/assets/images/calender.png");
import { TheMask } from "vue-the-mask";
import { Money } from "v-money";
import moment from "moment";
const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");

import { Validator } from "vee-validate";
import S3ImageGenerator from "@/views/onboarding/components/S3ImageGenerator";

const dictionary = {
  en: {
    attributes: {},
  },
};
Validator.localize(dictionary);
export default {
  name: "ProductSelection",
  props: {
    product: { type: Object },
    isComplete: { type: Object },
    applicationStatus: { type: String },
  },
  components: { TheMask, Money, S3ImageGenerator },

  data() {
    return {
      calenderImage: calenderImage,
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      hasBeenCompleted: {},
      rawProducts: [],
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
      tooltip: tooltip,
      activePlan: {},
      popupActivo: false,
      paymentSchedules: [],
      totalNumberOfPayments: 0,
      totalCustomerPayable: 0,
      merchantAmount: 0,
      amount: 1000,
      money2: {
        precision: 2,
        suffix: " %",
      },
      productText: "",
      payload: [],
      everyNumber: "1",
      weekOrMonthOrDay: "",
    };
  },

  methods: {
    ...mapActions("onboardMerchant", ["fetchPaymentPlanDetail"]),
    ...mapActions("plans", ["calculateOnPayLater"]),

    removePercentage(num) {
      return parseFloat(num.replace("%", ""));
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },

    validateForm() {
      if (this.product.productOption.length == 0) {
        this.errors.add({
          field: "payNowN",
          msg: "m",
        });
        this.$validator.validateAll();
        return false;
      }

      if (this.product.productOption.includes("pay-now")) {
        this.product.payNowPaymentMethods.forEach((method, index) => {
          let surcharge = parseFloat(method.surcharge);
          let msf = parseFloat(method.msf.replace("%", ""));
          if (surcharge > msf) {
            this.errors.add({
              field: `surcharge[${index}]`,
              msg: "Card surcharge can’t be greater than the MSF",
            });
          } else {
            this.errors.remove(`surcharge[${index}]`);
          }
        });
        let isCardSelected = this.product.payNowPaymentMethods.some(
          (method) => {
            if (method.isChecked && method.isChecked == "1") {
              return true;
            }
          }
        );

        if (!isCardSelected) {
          this.errors.add({
            field: "payNowPaymentMethods",
            msg: "m",
          });
        }
      }

      if (this.product.productOption.includes("pay-later")) {
        let isCardSelected = this.product.payLaterProducts.some((method) => {
          if (method.isChecked && method.isChecked == "1") {
            return true;
          }
        });

        if (!isCardSelected) {
          this.errors.add({
            field: "payLaterPaymentMethods",
            msg: "m",
          });
        }
      }
      return this.$validator.validateAll();
    },

    checkIfCardIsComplete(slug = "") {
      let self = this;
      if (slug === "pay-now") {
        let isCardSelected = this.product.payNowPaymentMethods.some(
          (method) => {
            if (method.isChecked && method.isChecked == "1") {
              return true;
            }
          }
        );
        if (!isCardSelected) {
          this.errors.add({
            field: "payNowPaymentMethods",
            msg: "m",
          });
        } else {
          this.errors.remove("payNowPaymentMethods");
        }
      }
      else if (slug === "pay-later") {
        let isCardSelected = this.product.payLaterProducts.some((method) => {
          if (method.isChecked && method.isChecked == "1") {
            return true;
          }
        });
        if (!isCardSelected) {
          this.errors.add({
            field: "payLaterPaymentMethods",
            msg: "m",
          });
        } else {
          this.errors.remove("payLaterPaymentMethods");
        }
      }
      let payNow = false;
      let payLater = false;

      if (this.product.productOption.length === 0) {
        payNow = false;
        payLater = false;
      } else {
        payNow = true;
        payLater = true;
      }

      self.product.payNowPaymentMethods.forEach((method, index) => {
        let surcharge = parseFloat(method.surcharge);
        let msf = parseFloat(method.msf.replace("%", ""));

        if (surcharge > msf) {
          this.errors.add({
            field: `surcharge[${index}]`,
            msg: "Card surcharge can't be greater than the MSF",
          });
        } else {
          this.errors.remove(`surcharge[${index}]`);
        }
      });

      for (const product of this.product.productOption) {
        if (this.rawProducts.includes(product)) {
          if (product === "pay-now") {
            const mySelectedProduct = this.product.payNowPaymentMethods.filter(
              (row) => row.isChecked == 1
            );
            if (mySelectedProduct.length === 0) {
              payNow = false;
            } else if (
              mySelectedProduct.some(
                (row, index) =>
                  (typeof row.surcharge === "string" &&
                    (row.surcharge.length === 0 ||
                      parseFloat(row.surcharge) > parseFloat(row.msf))) ||
                  parseFloat(row.surcharge) < 0.0 ||
                  this.errors.has(`surcharge[${index}]`)
              )
            ) {
              payNow = false;
            } else {
              payNow = true;
            }
          }

          if (product === "pay-later") {
            const mySelectedProductLater = this.product.payLaterProducts.filter((row) => row.isChecked === true);

            if (mySelectedProductLater.length === 0) {
              payLater = false;
            } else {
              payLater = true;
            }
          }

          this.$emit("handleCardHeight");
        }
      }

      this.isComplete.productSelectionStatus = payNow && payLater;
      this.$emit("checkDirty", true);
    },

    //* functions for plan configuration popup start here
    async openPlanConfigurationPopup(plan, index) {
      this.activePlan = plan;

      await this.calculateOnPayLater({ planId: plan._id, msf: this.product.payLaterProducts[index].msf }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        if (this.activePlan.productType == "B2C_BNPL") {
          this.payLaterSchedule = schedule.map((item) => {
            return {
              label: item.amount,
              value: new Date(item.date),
            };
          });
          this.paymentSchedules = this.payLaterSchedule;
          this.totalNumberOfPayments = this.payLaterSchedule.length;
        }
        this.productText = this.activePlan.productText;
        this.totalCustomerPayable = total.amount;
        this.merchantAmount = disbursement.amount;
      });

      //for finding week month or day
      if (this.activePlan.paymentFrequency) {
        switch (this.activePlan.paymentFrequency) {
          case "DAILY":
            this.everyNumber = "";
            this.weekOrMonthOrDay = "day";
            break;

          case "FORTNIGHTLY":
            this.everyNumber = "2";
            this.weekOrMonthOrDay = "weeks";
            break;

          case "MONTHLY":
            this.everyNumber = "";
            this.weekOrMonthOrDay = "month";
            break;

          case "WEEKLY":
            this.everyNumber = "";
            this.weekOrMonthOrDay = "week";
            break;
        }
      }
      this.popupActivo = true;
    },
    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    closePopup() {
      this.popupActivo = false;
      this.weekOrMonthOrDay = "";
      this.activePlan = {};
      this.paymentSchedules = [];
      this.totalCustomerPayable = "";
      this.merchantAmount = "";
    },

    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    payLaterDetail(planId, fields) {
      return this.fetchPaymentPlanDetail(planId)
        .then((res) => {
          const { msf, ...rest } = res.data.data[0];
          const newProductLegals = rest.legals.map((legalsData) => {
            return {
              _id: legalsData._id,
              version: legalsData.version,
              type: legalsData.type,
              user: legalsData.user
            };
          });
          rest.legals = newProductLegals;
          this.product.payLaterProducts = [
            ...this.product.payLaterProducts.filter((later) => later.customPlanId === planId).map(() => ({
              ...fields,
              ...rest,
            })),
            ...this.product.payLaterProducts.filter((later) => later.customPlanId !== planId),
          ];
        })
        .catch((ex) => {});
    },
    handleChange(index,flag){
      if (this.applicationStatus === "Info Requested") {
        let changedlabel = `payNowPaymentMethods[${index}]${flag}`;

        const data = {
          label: changedlabel,
          oldValue: this.payload.payNowPaymentMethods[index][flag],
          newValue: this.product.payNowPaymentMethods[index][flag]
        }

        this.$emit("resubmit", data);
      }
      this.checkIfCardIsComplete(flag);
    }
  },
  created() {
    this.hasBeenCompleted = this.isComplete;
    this.payload = JSON.parse(JSON.stringify(this.product));
    this.checkIfCardIsComplete()
    this.$validator.extend("surcharge", {
      getMessage: () => "Surcharge must be less then MSF.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^03\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^02\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^07\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^08\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^13\(?\d{2}\)?[\s.-]\d{2}$)|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^05\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^18\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})/
        ) !== null,
    });
    let isProductSet = true;
    let ispayNowPaymentMethodsSet = true;

    if (this.product.payLaterProducts.length == 0) {
      isProductSet = false;
    }

    if (!this.product.payNowPaymentMethods || this.product.payNowPaymentMethods.length == 0) {
      ispayNowPaymentMethodsSet = false
    }

    this.rawProducts = this.appActiveUser.paymentOptions.requestOptions;
    if (!ispayNowPaymentMethodsSet) {
      this.product.payNowPaymentMethods = this.rawProducts.includes("pay-now")
        ? [
            ...this.appActiveUser.cardOptions.map(({ isChecked, ...rest }) => ({
              isChecked: true,
              ...rest,
            })),
          ]
        : [];
    }
    if (!isProductSet) {
      this.product.productOption = this.rawProducts;
      this.product.payLaterProducts = this.appActiveUser.paymentOptions.payLaterPlan;

      if (this.rawProducts.includes("pay-later") && this.product.payLaterProducts.length > 0) {
        for (const d of this.product.payLaterProducts) {
          this.payLaterDetail(d.customPlanId, { msf: d.msf, isChecked: true });
        }
      } else {
        this.product.payLaterProducts = [];
      }

      this.isComplete.productSelectionStatus = true;
    }

    if (this.rawProducts.includes("pay-now") && this.product.payNowPaymentMethods.length > 0) {
      for (const d of this.product.payNowPaymentMethods) {
        d.surcharge = this.addZeroes(parseFloat(d.surcharge));
      }
    }
  },
  computed: {
    appActiveUser() {
      return Object.assign({}, this.$store.state.AppActiveUser);
    },
  },

  watch: {
    "product.productOption": function (newVal, oldVal) {
      if (newVal.length != oldVal.length) {
        if (this.product.productOption.length > 0) {
          this.errors.remove("payNowN");
        } else {
          this.errors.add({
            field: "payNowN",
            msg: "Wrong Credentials",
          });
        }
      }
    },
  },
};
</script>

