var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.customFieldsArray && _vm.customFieldsArray.length > 0)?_c('vs-row',_vm._l((_vm.customFieldsArray),function(text,index){return _c('vs-col',{key:index,staticClass:"mb-4 mt-4",attrs:{"vs-w":"6","vs-lg":"6","vs-sm":"12","vs-xs":"12"}},[_c('vs-row',[_c('div',{staticClass:"input-grp"},[_c('label',{staticClass:"vs-input--label w-full p-0",class:_vm.errors.has(("customFields." + (text.label))) ? 'text-danger' : ''},[_vm._v(_vm._s(text.label)+" "),(text.tooltipText)?_c('vs-tooltip',{staticClass:"icon-tooltip",attrs:{"text":text.tooltipText,"position":"top"}},[_c('img',{staticClass:"icon-control",attrs:{"src":_vm.tooltip,"alt":"tooltip"}})]):_vm._e()],1),(text.dataType == 'dropdown')?_c('div',[_c('vs-select',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: text.isRequired ? true : false,
              }),expression:"{\n                required: text.isRequired ? true : false,\n              }"}],staticClass:"selectExample w-full",attrs:{"data-vv-scope":"customFields","name":("" + (text.label)),"placeholder":_vm.checkPlaceholder(text.isRequired)},on:{"keyup":function($event){return _vm.handleChange(index)}},model:{value:(text.value),callback:function ($$v) {_vm.$set(text, "value", $$v)},expression:"text.value"}},_vm._l((text.dropdownOptions),function(dropdown,i){return _c('vs-select-item',{key:i,attrs:{"value":dropdown,"text":dropdown}})}),1),(_vm.errors.has(("customFields." + (text.label))))?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first(("customFields." + (text.label)))))]):_vm._e()],1):(text.dataType === 'currency')?_c('div',[_c('v-money-spinner',_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(("" + (text.isRequired ? 'required|customCurrency' : ''))),expression:"`${text.isRequired ? 'required|customCurrency' : ''}`"}],staticClass:"w-full currency-input",attrs:{"data-vv-scope":"customFields","name":("" + (text.label)),"placeholder":_vm.checkPlaceholder(text.isRequired)},on:{"change":function($event){return _vm.handleChange(index)}},model:{value:(text.value),callback:function ($$v) {_vm.$set(text, "value", $$v)},expression:"text.value"}},'v-money-spinner',_vm.config,false)),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has(("customFields." + (text.label)))),expression:"errors.has(`customFields.${text.label}`)"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first(("customFields." + (text.label))))+" ")])],1):_c('div',[(text.dataType !== "date")?_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: text.isRequired ? true : false,
              }),expression:"{\n                required: text.isRequired ? true : false,\n              }"}],staticClass:"w-full",attrs:{"data-vv-scope":"customFields","type":text.dataType,"size":"large","name":("" + (text.label)),"placeholder":_vm.checkPlaceholder(text.isRequired)},on:{"keyup":function($event){return _vm.handleChange(index)},"change":function($event){return _vm.handleChange(index)}},model:{value:(text.value),callback:function ($$v) {_vm.$set(text, "value", $$v)},expression:"text.value"}}):_vm._e(),_c('div',[(
                _vm.errors.has(("customFields." + (text.label))) &&
                text.dataType !== "date"
              )?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first(("customFields." + (text.label))))+" ")]):_vm._e()]),(text.dataType == "date")?_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: text.isRequired ? true : false,
              }),expression:"{\n                required: text.isRequired ? true : false,\n              }"}],staticClass:"w-full",attrs:{"data-vv-scope":"customFields","type":text.dataType,"size":"large","name":("" + (text.label))},on:{"change":function($event){return _vm.handleChange(index)}},model:{value:(text.value),callback:function ($$v) {_vm.$set(text, "value", $$v)},expression:"text.value"}}):_vm._e(),_c('div',[(
                _vm.errors.has(("customFields." + (text.label))) &&
                text.dataType == "date"
              )?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first(("customFields." + (text.label))))+" ")]):_vm._e()])],1)])])],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }