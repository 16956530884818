<template>
  <div class="req-temp-container onboarding-input-mobile onboarding-input-container mt-6 md:mt-18">
    <vs-row id="profile-page" class="merchant-onboarding-form">
        <!-- Business Information @submit.prevent="saveAsDraft" -->
        <vx-card class="accordin-card">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item :open="false || 'card' in $route.params && $route.params.card === 'businessInfo'" ref="businessInfo">
                <div slot="header">
                  <div class="flex flex-row items-center">
                    <img class="icon-control" :src="edit" alt="home" />
                    <h3 class="pl-4 fs-control">Business information</h3>
                    <div class="px-4 mr-1">
                      <vs-chip transparent :color="isBusinessInformationComplete ? 'green' : 'red'">{{ isBusinessInformationComplete ? "Complete" : "Incomplete" }}</vs-chip>
                      <vs-chip v-if="checkResubmitStatus && showComments('Business information')" color="warning" class="ml-2">Amendment required</vs-chip>
                    </div>
                  </div>
                </div>
                <vs-row>
                  <div class="w-full pr-4 md:pr-10" v-if="checkResubmitStatus && showComments('Business information')">
                    <div class="flex ml-10 mb-8 md:mb-12 onboard-comment">
                      <edit-icon class="m-0 w-5 h-5 onboard-comment-list"></edit-icon>
                      <p class="pl-4 mb-0">{{showComments('Business information')}}</p>
                    </div>
                  </div>
                  <!-- General Information -->
                  <div class="w-full mb-8">
                    <div class="flex mb-4 items-center">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.0775 1.07733C11.4029 0.751893 11.9306 0.751893 12.256 1.07733L15.5893 4.41066C15.9148 4.7361 15.9148 5.26374 15.5893 5.58917L6.42267 14.7558C6.26639 14.9121 6.05443 14.9999 5.83342 14.9999H2.50008C2.03984 14.9999 1.66675 14.6268 1.66675 14.1666V10.8333C1.66675 10.6122 1.75455 10.4003 1.91083 10.244L11.0775 1.07733ZM3.33341 11.1784V13.3333H5.48824L13.8216 4.99992L11.6667 2.8451L3.33341 11.1784Z"
                          fill="#BDBDBD"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.66675 18.3333C1.66675 17.8731 2.03984 17.5 2.50008 17.5H17.5001C17.9603 17.5 18.3334 17.8731 18.3334 18.3333C18.3334 18.7936 17.9603 19.1667 17.5001 19.1667H2.50008C2.03984 19.1667 1.66675 18.7936 1.66675 18.3333Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                      <h4 class="lg:text-xl md:text-lg text-sm mb-0 ml-5">General information</h4>
                    </div>
                    <hr class="line-hr mb-8" />
                    <GeneralInformation
                      :generalInformation="businessInformation"
                      :isComplete="isComplete"
                      :applicationStatus="applicationStatus"
                      @checkDirty="checkDirty"
                      @resubmit="addChanges"
                      ref="generalInformationComponent"
                      v-if="isFormReadyToRender"
                    />
                  </div>

                  <!-- business prodile -->
                  <div class="w-full mb-8" id="businessProfile">
                    <div class="flex items-center">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.0775 1.07733C11.4029 0.751893 11.9306 0.751893 12.256 1.07733L15.5893 4.41066C15.9148 4.7361 15.9148 5.26374 15.5893 5.58917L6.42267 14.7558C6.26639 14.9121 6.05443 14.9999 5.83342 14.9999H2.50008C2.03984 14.9999 1.66675 14.6268 1.66675 14.1666V10.8333C1.66675 10.6122 1.75455 10.4003 1.91083 10.244L11.0775 1.07733ZM3.33341 11.1784V13.3333H5.48824L13.8216 4.99992L11.6667 2.8451L3.33341 11.1784Z"
                          fill="#BDBDBD"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.66675 18.3333C1.66675 17.8731 2.03984 17.5 2.50008 17.5H17.5001C17.9603 17.5 18.3334 17.8731 18.3334 18.3333C18.3334 18.7936 17.9603 19.1667 17.5001 19.1667H2.50008C2.03984 19.1667 1.66675 18.7936 1.66675 18.3333Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                      <h3 class="lg:text-xl md:text-lg text-sm ml-5">Business profile</h3>
                    </div>
                    <hr class="line-hr mt-4 mb-8" />
                    <div class="onboarding-form">
                      <BusinessProfile
                        :businessProfile="businessProfile"
                        :customFieldsArray="customFieldsArray"
                        :applicationStatus="applicationStatus"
                        :isComplete="isComplete"
                        ref="businessProfileComponent"
                        v-if="isFormReadyToRender"
                        @profileHeight="profileHeight"
                        @checkDirty="checkDirty"
                        @resubmit="addChanges"
                      />
                    </div>
                  </div>

                  <!-- Primary contact person -->
                  <div class="w-full mb-8" id="contactDetails">
                    <div class="flex items-center">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.0775 1.07733C11.4029 0.751893 11.9306 0.751893 12.256 1.07733L15.5893 4.41066C15.9148 4.7361 15.9148 5.26374 15.5893 5.58917L6.42267 14.7558C6.26639 14.9121 6.05443 14.9999 5.83342 14.9999H2.50008C2.03984 14.9999 1.66675 14.6268 1.66675 14.1666V10.8333C1.66675 10.6122 1.75455 10.4003 1.91083 10.244L11.0775 1.07733ZM3.33341 11.1784V13.3333H5.48824L13.8216 4.99992L11.6667 2.8451L3.33341 11.1784Z"
                          fill="#BDBDBD"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.66675 18.3333C1.66675 17.8731 2.03984 17.5 2.50008 17.5H17.5001C17.9603 17.5 18.3334 17.8731 18.3334 18.3333C18.3334 18.7936 17.9603 19.1667 17.5001 19.1667H2.50008C2.03984 19.1667 1.66675 18.7936 1.66675 18.3333Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                      <h3 class="lg:text-xl md:text-lg text-sm ml-5">Contact details</h3>
                    </div>
                    <hr class="line-hr mt-4 mb-8" />
                    <div class="onboarding-form">
                      <p class="text-base instruction-style mb-8">We’ll communicate with you using the details you add below</p>
                      <ContactDetails
                        :contactDetails="contactDetails"
                        :tooltip="tooltip"
                        :isContactCommonForAll="isContactCommonForAll"
                        :isComplete="isComplete"
                        :applicationStatus="applicationStatus"
                        ref="contactDetailsComponent"
                        v-if="isFormReadyToRender"
                        @checkDirty="checkDirty"
                        @changeToggle="changeToggle"
                        @resubmit="addChanges"
                      />
                    </div>
                  </div>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!-- Business ownership -->
        <vx-card class="accordin-card mt-6" id="businessOwnershipArea">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item :open="false || 'card' in $route.params && $route.params.card === 'businessOwnership'" ref="businessOwnership">
                <div slot="header">
                  <div class="flex flex-row items-center">
                    <img class="icon-control" :src="filetext" alt="filetext" />
                    <h3 class="pl-4 fs-control">Business ownership    </h3>
                    <div class="px-4 mr-1">
                      <vs-chip transparent :color="isBusinessWonershipComplete ? 'green' : 'red'">{{ isBusinessWonershipComplete ? "Complete" : "Incomplete" }}</vs-chip>
                      <vs-chip v-if="checkResubmitStatus && showComments('Business ownership')" color="warning" class="ml-2">Amendment required</vs-chip>
                    </div>
                  </div>
                </div>
                <vs-row>
                  <div class="w-full pr-4 md:pr-10" v-if="checkResubmitStatus && showComments('Business ownership')">
                    <div class="flex ml-10 mb-8 md:mb-12 onboard-comment">
                      <edit-icon class="m-0 w-5 h-5 onboard-comment-list"></edit-icon>
                      <p class="pl-4 mb-0">{{showComments('Business ownership')}}</p>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="mb-6 md:mb-10 information onboarding-form instruction-style md:pr-10">
                      <p> Please provide further information about your business legal structure.</p><br>
                      <p>
                        The entities you list below are the legal entities that contract with your
                        customers/or act on behalf of your business. An 'Individual' entity type may
                        be applicable for sole traders and individual trustees. The 'Company' entity
                        type is applicable for Pty Ltd companies and corporate trustees.
                        Please add as many entities as required that operate under your trading name.
                      </p>
                    </div>
                    <BusinessEntity
                      ref="businessWonership"
                      :entityList="entityList"
                      :isComplete="isComplete"
                      :businessOwnershipCustomFields="businessOwnershipCustomFields"
                      :applicationStatus="applicationStatus"
                      @checkDirty="checkDirty"
                      @resubmit="addChanges"
                      v-if="isFormReadyToRender"
                    />
                  </div>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!-- Bank Account -->
        <vx-card class="accordin-card mt-6">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item :open=" 'card' in $route.params && $route.params.card === 'bankaccount'" ref="bankaccount" id="bank__account">
                <div slot="header">
                  <div class="flex flex-row items-center">
                    <img class="icon-control" :src="bank" alt="bank" />
                    <h3 class="pl-4 fs-control">Bank account</h3>
                    <div class="px-4 mr-1">
                      <vs-chip :color="isBankAccountComplete ? 'green' : 'red'">{{ isBankAccountComplete ? "Complete" : "Incomplete" }}</vs-chip>
                      <vs-chip v-if="checkResubmitStatus && showComments('Bank accounts')" color="warning" class="ml-2">Amendment required</vs-chip>
                    </div>
                  </div>
                </div>
                <vs-row>
                  <div class="w-full pr-4 md:pr-10" v-if="checkResubmitStatus && showComments('Bank accounts')">
                    <div class="flex ml-10 mb-8 md:mb-12 onboard-comment">
                      <edit-icon class="m-0 w-5 h-5 onboard-comment-list"></edit-icon>
                      <p class="pl-4 mb-0">{{showComments('Bank accounts')}}</p>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="mb-8 information onboarding-form instruction-style">
                      <p>
                        Please provide details of the bank account/s you wish to receive money into (<strong>settlement account</strong>) and to pay fees from (<strong>billing account</strong>). An account configuration will be linked to payment requests so that the moneys are received to the right bank account. You may add more than 1 account configuration to suit the needs of your business.
                      </p>
                    </div>
                    <BankAccount
                      :bankAccount="bankAccount"
                      :isComplete="isComplete"
                      :entityList="entityList"
                      ref="bankAccountComponent"
                      v-if="isFormReadyToRender"
                      :applicationStatus="applicationStatus"
                      @handleCardHeight="handleCardHeight('bankaccount')"
                      @checkDirty="checkDirty"
                      @resubmit="addChanges"
                    />
                  </div>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!-- Product Selection -->
        <vx-card class="accordin-card mt-6">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item :open="'card' in $route.params && $route.params.card === 'productSelection'" ref="productSelection">
                <div slot="header">
                  <div class="flex flex-row items-center">
                    <img class="icon-control" :src="product" alt="product" />
                    <h3 class="pl-4 fs-control">Product selection</h3>
                    <div class="px-4 mr-1">
                      <vs-chip transparent :color="isProductSelectionComplete ? 'green' : 'red'">{{ isProductSelectionComplete ? "Complete" : "Incomplete" }}</vs-chip>
                      <vs-chip v-if="checkResubmitStatus && showComments('Product selection')" color="warning" class="ml-2">Amendment required</vs-chip>
                    </div>
                  </div>
                </div>
                <div id="productSelectionArea">
                  <div class="w-full pr-4 md:pr-10" v-if="checkResubmitStatus && showComments('Product selection')">
                    <div class="flex ml-10 mb-8 md:mb-12 onboard-comment">
                      <edit-icon class="m-0 w-5 h-5 onboard-comment-list"></edit-icon>
                      <p class="pl-4 mb-0">{{showComments('Product selection')}}</p>
                    </div>
                  </div>
                  <ProductSelection
                    :product.sync="productSelection"
                    :isComplete="isComplete"
                    :applicationStatus="applicationStatus"
                    @handleCardHeight="handleCardHeight('productSelection')"
                    @checkDirty="checkDirty"
                    ref="productSelectionComponent"
                    v-if="isFormReadyToRender"
                    @resubmit="addChanges"
                  />
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <div class="mt-10 flex justify-end">
          <vs-alert class="custom-alert mb-8 single-line-alert" v-if="showMandotoryBox" color="danger" icon="error_outline">
            <span class="text-red">Please populate all mandatory fields before proceeding.</span>
          </vs-alert>
        </div>
        <!--   Buttons   -->
        <div class="mt-10 flex w-full flex-wrap justify-end ob-btn-container">
          <vs-button flat size="large" v-round class="ml-auto only-border-btn" :disabled="!isSaveAsDraftEnabled" @click="saveForm()">Save</vs-button>
          <vs-button size="large" v-round class="ml-8 next-color" @click="goToReview">Next</vs-button>
        </div>

        <!-- saved alert action -->
        <div class="mt-10 flex w-full flex-wrap" v-if="showFailureAlert">
          <div class="saveFailure lg:w-4/12 w-full">
            <div class="flex">
              <div><img class="icon-control" :src="alertCircle" alt="alert" /></div>
              <div class="flex flex-col ml-4">
                <h4 class="text-base font-medium p-0">Unable to save changes</h4>
                <p class="block text-sm">Sorry, the changes you made were not saved. Please try again.</p>
              </div>
              <div><feather-icon icon="XIcon" class="ml-1" @click="closeAlert" /></div>
            </div>
          </div>
        </div>

        <div class="mt-10 flex w-full flex-wrap" v-if="showSuccessAlert">
          <div class="saveSuccess lg:w-4/12 w-full">
            <div class="flex">
              <div><img class="icon-control" :src="successCircle" alt="alert" /></div>
              <div class="flex flex-col ml-4">
                <h4 class="text-base font-medium p-0">Saved successfully</h4>
                <p class="block text-sm">Changes you made were saved successfully.</p>
              </div>
              <div><feather-icon icon="XIcon" class="ml-1" @click="closeAlert" /></div>
            </div>
          </div>
        </div>
    </vs-row>
    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
  </div>
</template>

<script>
import { clearLocalStorage } from "../../../helper/localStorage.js";
import { mapActions } from "vuex";
import { ErrorBag } from "vee-validate";
import GeneralInformation from "./businessInformation/GeneralInformation";
import BeneficiaryOwner from "./businessInformation/BeneficiaryOwner";
import AuthorisedSignature from "./businessInformation/AuthorisedSignature";
import ContactDetails from "./businessInformation/ContactDetails";
import BusinessProfile from "./businessProfile/BusinessProfile";
import ProductSelection from "./product/ProductSelection.vue";
import BankAccount from "./bankAccount/bankAccounts.vue";
import BusinessEntity from "./businessWonership/BusinessWonership"

const edit = require("@/assets/images/edit.svg");
const bank = require("@/assets/images/bank-blue.png");
const filetext = require("@/assets/images/file-text.png");
const product = require("@/assets/images/product.png");

import LeavePopup from "@/views/components/LeavePopup";
import { scroller } from "vue-scrollto/src/scrollTo";

const alertCircle = require("@/assets/images/alert-circle.svg");
const successCircle = require("@/assets/images/success-circle.svg");

import { Money } from "v-money";
import { EventBus } from "../../../event-bus";

const tooltipicon = require("@/assets/images/tooltip_dark.svg");

import {EditIcon} from "vue-feather-icons";
import { sentryLog } from "../../../helper/sentryLog";

const firstScrollTo = scroller();
export default {
  name: "index",
  components: {
    GeneralInformation,
    BeneficiaryOwner,
    AuthorisedSignature,
    ContactDetails,
    BusinessProfile,
    BankAccount,
    ProductSelection,
    LeavePopup,
    Money,
    BusinessEntity,
    EditIcon
  },
  data() {
    return {
      isLogout:false,
      id: "",
      validation: {
        generalInformationValidation: false,
        beneficiaryOwnersValidation: false,
        authorisedSignaturesValidation: false,
        businessProfileValidation: false,
        bankAccountValidation: false,
        productSelectionValidation: false,
        businessWonership: false,
      },
      isComplete: {
        generalInformationStatus: false,
        contactDetailsStatus: false,
        bankAccountStatus: false,
        businessProfileStatus: false,
        productSelectionStatus: false,
        businessWonershipStatus: false,
      },
      businessInformation: {
        industry: "",
        category: "",
        websiteUrl: "",
        tradingName: "",
        businessEmailAddress: "",
        businessPhoneNumber: "",
      },
      isContactCommonForAll: false,
      contactDetails: [
        { name: "Account owner" },
        { name: "Accounts department" },
        { name: "Technical support" }
      ],
      tooltip:[
        "We will contact the account owner for any account related matters such as new products. Any authorisation required for changes to the account will be directed to this person. ",
        "Any finance and billing related correspondence will be sent to these contact points.",
        "Any technical issues and communications will be sent to these contact points.",
      ],
      businessProfile: {
        product: "",
        productDescription: "",
        businessSummary: "",
        annualTurnoverLast12Month: 0.0,
        forecastTurnover: 0.0,
        profitLast12Month: 0.0,
        averageTransactionSize: 0,
        transactionsPerMonth: 0,
        averageDebtors: 0,
      },
      bankAccount: [
        {
          accountName: "",
          bsb: "",
          accountNumber: "",
          bankStatement: "",
          isUsedForSettlement: false,
          isUsedForBilling: false,
          hasFileUploaded: false,
          uniqueIdForTill: "",
          entity: "",
          accountNameForBilling: "",
          bsbForBilling: "",
          accountNumberForBilling: "",
          bankStatementForBilling: "",
          existingBankAccountForBilling: false,
          existingBankAccount: false,
          sameAsSettlementAccount: false,
          hasFileUploadedForBilling: false,
          entityForBilling: "",
        },
      ],
      productSelection: {
        productOption: [],
        payNowPaymentMethods: [],
        payLaterProducts: [],
      },
      isSubmitted: false,
      hasDataLoaded: false,
      edit: edit,
      bank: bank,
      filetext: filetext,
      product: product,
      errorBag: null,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
      childFormDirty: false,
      doScroll: true,
      showMandotoryBox: false,
      alertCircle: alertCircle,
      successCircle: successCircle,
      showSuccessAlert: false,
      showFailureAlert: false,
      prevRoute: "",
      isFieldRequired: false,
      customFieldsArray: [],
      businessOwnershipCustomFields: [],
      currencyMask: {
        prefix: "$ ",
        precision: 0,
        masked: false,
      },
      tooltipicon:tooltipicon,
      entityList:[],
      applicationStatus: "",
      resubmission: [],
      resubmitChanges: [],
    };
  },

  methods: {
    ...mapActions("onboardMerchant", [
      "saveWebForm",
      "fetchOnboardDetailWithId",
      "removeTrustDeedDocument",
      "fetchPaymentPlanDetail",
      "updateApplication",
    ]),

    trustDeedUpdated(value) {
      if (value === "yes") {
        // todo - this readlly needs refactoring
        this.isFieldRequired =
          this.businessInformation.isMoreThan25 === "no" ? false : !this.businessInformation.hasOwnProperty("beneficialDocuments")
            ? true : this.businessInformation.beneficialDocuments.length > 0
            ? false : true;
      } else {
        this.isFieldRequired = false;
      }

      this.isComplete.trustDeedsStatus = this.isFieldRequired ? false : true;
    },

    async saveForm(params) {
      this.$vs.loading();
      this.isSubmitted = true;

      const formData = new FormData();

      for (let index = 0; index < this.bankAccount.length; index++) {
        if (this.bankAccount[index].bankStatement && typeof this.bankAccount[index].bankStatement !== "string") {
          formData.append("statementDocumentFile", this.bankAccount[index].bankStatement);
        }

        if (this.bankAccount[index].bankStatementForBilling && typeof this.bankAccount[index].bankStatementForBilling !== "string") {
          formData.append("statementDocumentFileForBilling", this.bankAccount[index].bankStatementForBilling);
        }
      }

      for (let index = 0; index < this.entityList.length; index++) {
        if (this.entityList[index].trustDeedFiles && typeof this.entityList[index].trustDeedFiles !== "string") {
          formData.append("trustDeedDocumentFiles", this.entityList[index].trustDeedFiles);
        }
      }

      formData.append("userId", this.userId);
      formData.append("isComplete", JSON.stringify(this.isComplete));
      formData.append("businessInfo", JSON.stringify(this.businessInformation));
      formData.append("businessProfile", JSON.stringify(this.businessProfile));
      formData.append("contactDetails", JSON.stringify(this.contactDetails));
      formData.append("isContactCommonForAll", JSON.stringify(this.isContactCommonForAll));
      formData.append("bankAccount", JSON.stringify(this.bankAccount));
      formData.append("businessOwnership",JSON.stringify(this.entityList));
      formData.append("productSelection", JSON.stringify(this.productSelection));
      formData.append("forReview", params ? "yes" : "");

      if (this.customFieldsArray && this.customFieldsArray.length > 0) {
        formData.append("onboardingCustomFields", JSON.stringify(this.customFieldsArray));
      }

      if (this.applicationStatus === 'Info Requested') {
        formData.append("status", this.applicationStatus);
        formData.append("resubmitChanges",JSON.stringify(this.resubmitChanges));
      }

      const obj = {
        data: formData,
        config: { header: { "Content-Type": "multipart/form-data" } },
      };
      let returnBack = false;
      await this.saveWebForm(obj).then((response) => {
        this.$vs.loading.close();
        this.isSaved = true;
        this.showSuccessAlert = true;
        returnBack = true;
      }).catch((ex) => {
        this.errors.clear();
        this.$vs.loading.close();
        this.showFailureAlert = true;

        if (ex.response.status == 422) {
          this.errors.add(ex.response.data.data);
        }
      });
      return returnBack;
    },

    getDetails() {
      this.$vs.loading();
      this.fetchOnboardDetailWithId(this.id).then((result) => {
        const businessWonershipFields = result.data.data.onboardingRequest.businessOwnershipFields;
        this.businessOwnershipCustomFields = [...businessWonershipFields];
        this.isComplete = result.data.data.isComplete ? result.data.data.isComplete : this.isComplete;

        if (result.data.data.businessOwnership && result.data.data.businessOwnership.length > 0) {
          this.entityList = result.data.data.businessOwnership;
          const newData = this.entityList.map((entity) => {
            entity.entityId = entity.entityId ? entity.entityId : this.createUniqueId("E");
            return entity;
          });
          this.entityList = [...newData];
        } else {
          this.entityList.push({
            entityType: "",
            companyName: "",
            abn: "",
            acn: "",
            fullName: "",
            emailAddress: "",
            mobileNumber: "",
            registeredAddress: {
              manualMode: false,
              appartmentDetail: "",
              streetAddress: "",
              suburb: "",
              state: "",
              postCode: "",
            },
            isSameAsRegisteredAddress: false,
            tradingAddress: {
              manualMode: false,
              appartmentDetail: "",
              streetAddress: "",
              suburb: "",
              state: "",
              postCode: "",
            },
            isEntityActAsTrust: false,
            trustName: "",
            trustAbn: "",
            trustDeedFiles: "",
            beneficialOwners: [],
            agentLicenseNumber: "",
            entityId: this.createUniqueId("E"),
            boCustomFields: JSON.parse(JSON.stringify(businessWonershipFields))
          });
        }

        if (result.data.data.onboardingRequest && result.data.data.onboardingRequest.onboardingCustomFields) {
          this.customFieldsArray = result.data.data.onboardingRequest.onboardingCustomFields;
        }

        if (this.customFieldsArray.length == 0) {
          this.isComplete.businessProfileStatus = true;
        }

        if (result.data.data && result.data.data.status !== "Pending") {
          if (result.data.data.onboardingCustomFields && result.data.data.onboardingCustomFields.length > 0) {
            this.customFieldsArray = result.data.data.onboardingCustomFields;
            let filteredCFArray = this.customFieldsArray.filter((element) => {
              return (element.isRequired == true && (!element.value || element.value.length === 0));
            });

            this.isComplete.businessProfileStatus = (filteredCFArray.length === 0);
          }

          if (!result.data.data.onboardingCustomFields || result.data.data.onboardingCustomFields.length === 0) {
            this.isComplete.businessProfileStatus = true;
          }

          const { hasFileUploaded, ...rest } = result.data.data.bankAccount;
          this.bankAccount = result.data.data.bankAccount.map(({ hasFileUploaded, ...rest }) => ({
              hasFileUploaded: false,
              ...rest,
            })
          );

          if ("contactDetails" in result.data.data ) {
            for (let index = 0; index < result.data.data.contactDetails.length; index++) {
              this.contactDetails[index].name = result.data.data.contactDetails[index].name;
              this.contactDetails[index].email = result.data.data.contactDetails[index].email;
              this.contactDetails[index].phoneNumber = result.data.data.contactDetails[index].phoneNumber;
            }
          }

          this.isContactCommonForAll = result.data.data.isContactCommonForAll ? result.data.data.isContactCommonForAll : false;
          this.businessProfile = result.data.data.businessProfile;
          this.productSelection = result.data.data.productSelection;

          if (result.data.data.status == "Info Requested") {
            this.resubmitChanges = result.data.data.resubmitChanges || [];
          }
        }

        this.handleCardHeight(this.$route.params.card);
        this.applicationStatus = result.data.data.status;
        this.resubmission = result.data.data.resubmission || [];

        let currentStep = result.data.data.currentStep;

        if (result.data.data.businessInfo) {
          this.businessInformation = result.data.data.businessInfo;
        }

        if (this.prevRoute.path == "/login" && currentStep && result.data.data.status != "Awaiting approval") {
          if (currentStep == "review") {
            this.$router.push({
              name: "reviewApplication",
              params: { introducerRequestedId: this.id },
            });
          } else if (currentStep == "terms") {
            this.$router.push({
              name: "termsAndCondition",
              params: { introducerRequestedId: this.id },
            });
          }
        }

        this.hasDataLoaded = true;
        this.$vs.loading.close();
      }).catch((ex) => {
        sentryLog(ex, { message: "Unbale to load correctly please reload this page.", onboardingRequestId: this.id });
        this.$vs.notify({
          title: "Onboarding Request",
          text: "Unbale to load correctly please reload this page.",
          color: "danger",
          iconPack: "feather",
          position: "top-right",
          time: 10000
        });
        this.$vs.loading.close();
      });
    },

    gotoSelectedRef(refName) {
      if (refName) {
        const elmnt = document.getElementById(refName);
        elmnt.scrollIntoView({
          behavior: "smooth",
        });

        if (!refName == "productSelectionArea") {
          elmnt.style.marginTop = "150px !Important";
        }
      }
    },

    async goToReview() {
      this.validation.businessWonership = await this.$refs.businessWonership.validateForm();
      this.validation.businessProfileValidation = await this.$refs.businessProfileComponent.validateForm();
      this.validation.generalInformationValidation = await this.$refs.generalInformationComponent.validateForm();
      this.validation.bankAccountValidation = await this.$refs.bankAccountComponent.validateForm();
      this.validation.productSelectionValidation = await this.$refs.productSelectionComponent.validateForm();
      this.validation.contactDetailsComponent = await this.$refs.contactDetailsComponent.validateForm();
      const bankFilevalidation = await this.$refs.bankAccountComponent.validateFile();
      const customFieldsValidation = await this.$validator.validate("customFields.*");

      if (
        this.validation.businessProfileValidation &&
        this.validation.generalInformationValidation &&
        this.validation.bankAccountValidation &&
        this.validation.productSelectionValidation &&
        this.validation.contactDetailsComponent &&
        this.validation.businessWonership &&
        this.isPreviewAndSubmitEnabled &&
        customFieldsValidation
      ) {
        this.showMandotoryBox = false;
        this.saveForm({ forReview: true }).then(async (response) => {
          if (response) {
            this.isSaved = true;
            let data = {
              _id: this.id,
              info: { currentStep: "review" },
            };
            await this.updateApplication(data).catch((err) => {});
            this.$router.push({
              name: "reviewApplication",
              params: { introducerRequestedId: this.id },
            });
          }
          return false;
        }).catch((ex) => {
          return false;
        });
      } else {
        this.showMandotoryBox = true;
      }
    },

    handleCardHeight(card) {
      try {
        if (card === "bankaccount") {
          this.$refs.bankaccount.maxHeight = "none !important";
        }

        if (card === "businessInfo") {
          this.$refs.businessInfo.maxHeight = "none !important";
        }

        if (card === "productSelection") {
          this.$refs.productSelection.maxHeight = "none !important";
        }

        if (card === "businessWonership") {
          this.$refs.businessWonership.maxHeight = "none !important";
        }
      } catch (ex) {
        sentryLog(ex, { message: "Unable to extend height of the card" });
      }
    },

    customUploadButton() {
      this.errorBag.clear();
      if (this.businessInformation.beneficialDocuments.length > 5)
        return this.errorBag.add({
          field: "beneficialDocument",
          msg: "Only 5 files are allowed to upload",
        });

      this.$refs.uploadBeneficialDocumentRef.click();
    },

    uploadBeneficialDocument(event, maxLimit) {
      let totalFilesSelected = 0;
      totalFilesSelected += this.businessInformation.beneficialDocuments.length;
      if (totalFilesSelected + event.target.files.length > 5) {
        return this.errorBag.add({
          field: "beneficialDocument",
          msg: "Only 5 files are allowed to upload.",
        });
      }

      if (event.target.files.length > 0) {
        for (let file of event.target.files) {
          if (file && file["type"].split("/")[1] !== "pdf") {
            return this.errorBag.add({
              field: "beneficialDocument",
              msg: "Only pdf type of file is allowed.",
            });
          }
        }
      }

      this.businessInformation.beneficialDocuments = [
        ...this.businessInformation.beneficialDocuments,
        ...event.target.files,
      ];
      this.isComplete.trustDeedsStatus = true;
    },

    async deleteSelectedBeneficialDocument(document, index) {
      this.errorBag.clear();
      const payload = {
        onboardingRequestId: this.$route.params.introducerRequestedId,
        document,
      };
      await this.removeTrustDeedDocument(payload);
      this.businessInformation.beneficialDocuments.splice(index, 1);
      this.isFieldRequired = this.businessInformation.beneficialDocuments.length === 0;
      this.isComplete.trustDeedsStatus = !(this.businessInformation.beneficialDocuments.length === 0);
    },

    profileHeight() {
      this.$refs.businessInfo.maxHeight = "none !important";
    },

    checkDirty(val) {
      this.childFormDirty = val;
    },

    changeToggle(val) {
      this.isContactCommonForAll = val;
    },

    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          break;

        case "cancel":
          this.popupActive = false;
          break;

        default:
          this.popupActive = false;
      }
    },

    closeAlert() {
      this.showSuccessAlert = false;
      this.showFailureAlert = false;
    },

    createUniqueId(character) {
      let firstBits = Math.floor(1000 + Math.random() * 9000);
      let secondBits = Math.floor(1000 + Math.random() * 9000);
      let uniqueId = `${character}-${firstBits}-${secondBits}`;
      return uniqueId;
    },

    showComments(section){
      let objData = this.resubmission.find((obj) => obj.name === section);
      if (objData) {
        return objData.comment;
      }
      return false;
    },

    addChanges(e){
      if (this.resubmitChanges.length > 0) {
        let objData = this.resubmitChanges.findIndex((obj) => obj.label === e.label);

        if (objData >= 0) {
          this.resubmitChanges.splice(objData,1)
        }
      }

      if (e.oldValue != e.newValue) {
        this.resubmitChanges.push(e);
      }
    }
  },

  mounted() {
    this.errorBag = new ErrorBag();
    this.getDetails();
  },

  updated() {
    if (this.doScroll) {
      this.gotoSelectedRef(this.$route.query.scrollTo);
      this.doScroll = false;
    }
  },

  destroyed() {
    this.doScroll = true;
  },

  created() {
    this.id = this.$route.params.introducerRequestedId;
    EventBus.$on("logoutSuccess", value => {
      this.isLogout =  value.logout;
    });
  },

  computed: {
    appActiveUser() {
      return Object.assign({}, this.$store.state.AppActiveUser);
    },

    isBusinessWonershipComplete() {
      return this.isComplete.businessWonershipStatus;
    },

    isBusinessInformationComplete() {
      return (this.isComplete.generalInformationStatus && this.isComplete.businessProfileStatus && this.isComplete.contactDetailsStatus);
    },

    isBankAccountComplete() {
      return this.isComplete.bankAccountStatus;
    },

    isProductSelectionComplete() {
      return this.isComplete.productSelectionStatus;
    },

    isPreviewAndSubmitEnabled() {
      return (
        this.isComplete.generalInformationStatus &&
        this.isComplete.contactDetailsStatus &&
        this.isComplete.businessProfileStatus &&
        this.isComplete.bankAccountStatus &&
        this.isComplete.productSelectionStatus &&
        this.isComplete.businessWonershipStatus
      );
    },

    isSaveAsDraftEnabled() {
      return true;

    },

    isRequiredvalidationOk() {
      return (
        this.validation.businessProfileValidation &&
        this.validation.generalInformationValidation &&
        this.validation.beneficiaryOwnersValidation &&
        this.validation.bankAccountValidation &&
        this.validation.authorisedSignaturesValidation &&
        this.validation.productSelectionValidation &&
        this.validation.primaryContactComponent
      );
    },

    isFormReadyToRender() {
      return this.hasDataLoaded;
    },

    checkResubmitStatus() {
      let checkStatus = ['Info Requested'].includes(this.applicationStatus);
      return checkStatus;
    }
  },

  async beforeRouteLeave(to, from, next) {
    if (this.isLogout) {
      await this.saveForm();
      next();
      clearLocalStorage();
    } else {
      if ((this.isFormDirty || this.childFormDirty) && !this.isSaved) {
        this.nextObj = next;
        this.popupActive = true;
      } else {
        next();
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>